import { template as template_538e1ef38026461993ae5a8df6123f74 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from 'tio-employee/components/accounts/linked-loans-summary';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import NumberBadge from '../todo/number-badge';
import Todo from '../todo';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type PersonModel from 'tio-common/models/person';
import type RecommendationModel from 'tio-common/models/recommendation';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
export interface PslfDashboardActionsEstimatorTodoSignature {
    Args: {
        accounts: AccountModel[];
        itemNo: string;
        loanSummary: LoanSummary;
        person: PersonModel;
        recommendation?: RecommendationModel;
        isCanceling?: boolean;
    };
    Element: HTMLElement;
}
export default class PslfDashboardActionsEstimatorTodoComponent extends Component<PslfDashboardActionsEstimatorTodoSignature> {
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @tracked
    loanErrors = [];
    @tracked
    showMissingInfoDialog = false;
    @tracked
    showEstimatorBlockDialog = false;
    get estimatorComplete() {
        return !!this.args.recommendation;
    }
    @action
    checkLoansAndGoToEstimator() {
        const summary = this.args.loanSummary;
        // TODO: The loan summary needs a complete overhaul. This isn't ideal but will work for now.
        this.loanErrors = /* summary.errors || */ [];
        if (!summary.summaryByLoanType.length) {
            this.showEstimatorBlockDialog = true;
        } else {
            if (this.loanErrors.length === 0) {
                this.router.transitionTo('authenticated.pslf.dashboard.forgiveness-estimator.index');
            } else {
                this.showMissingInfoDialog = true;
            }
        }
    }
    static{
        template_538e1ef38026461993ae5a8df6123f74(`
    <Todo
      @title={{t "pslf_dashboard.actions.forgiveness_estimator_status"}}
      @isComplete={{this.estimatorComplete}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <CardInfo
          @icon="calculate"
          @text={{t "pslf_dashboard.actions.let_us_estimate_forgive"}}
          class="w-fit"
        />
      </:body>
      <:actions>
        <Button @intent="primary" {{on "click" this.checkLoansAndGoToEstimator}}>
          {{t "pslf_dashboard.actions.forgiveness_estimator"}}
        </Button>
      </:actions>
    </Todo>

    <Modal
      @isOpen={{this.showMissingInfoDialog}}
      @onClose={{fn (mut this.showMissingInfoDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{@person.firstName}},
        {{t "pslf_dashboard.actions.dialog.we_need_your_help"}}
      </m.Header>
      <m.Body>
        <p class="text-tio-gray-400 mb-5">
          {{t "pslf_dashboard.actions.dialog.please_provide_info"}}
        </p>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @loanSummary={{@loanSummary}}
          @person={{@person}}
        />
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.checkLoansAndGoToEstimator}}
          @submitText="Continue to estimator"
          @onCancel={{fn (mut this.showMissingInfoDialog) false}}
        />
      </m.Footer>
    </Modal>

    <Modal
      @isOpen={{this.showEstimatorBlockDialog}}
      @onClose={{fn (mut this.showEstimatorBlockDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{t "pslf_dashboard.actions.dialog.before_proceeding"}}
      </m.Header>
      <m.Body>
        <p>{{t "pslf_dashboard.actions.dialog.we_will_not_be_able"}}</p>
      </m.Body>

      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}}
          @submitText={{t "ok"}}
        />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
