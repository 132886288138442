import { template as template_71dd7d03e05c4fb7b27855943c61ff5d } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Divider } from 'tio-ui/components/utilities';
import { Input, Form, RadioGroup, Select } from 'tio-ui/components/forms';
import { not, or } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { string } from 'yup';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { VStack } from 'tio-ui/components/layout';
import Component from '@glimmer/component';
import embedCalendly from 'tio-common/modifiers/embed-calendly';
import ENV from 'tio-employee/config/environment';
import TioAlert from 'tio-common/components/tio/alert';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type IntlService from 'ember-intl/services/intl';
import type SessionContextService from 'tio-employee/services/session-context';
export default class RepayingStudentDebtCoachSchedulerComponent extends Component {
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @tracked
    showSchedulingCalendar = false;
    @tracked
    submissionError = false;
    @tracked
    calendlyError = false;
    @tracked
    didSubmitSuccessfully = false;
    @tracked
    submitEnabled = false;
    @tracked
    emailPreferred = false;
    @tracked
    data: Record<string, unknown> = {};
    paymentPlans = [
        '10 year standard repayment plan',
        'Graduated',
        'Extended',
        'Pay As You Earn (PAYE)',
        'SAVE (formerly REPAYE)',
        'Income-Based Repayment (IBR)',
        'Income-Contingent Repayment',
        'Other'
    ];
    get plainText() {
        const answers = {
            scheduling_question_topic: this.data.topic,
            scheduling_question_repayment_goal: this.data.repaymentGoal,
            scheduling_question_loan_type: this.data.studentLoanType,
            scheduling_question_repayment_plan: this.data.repaymentPlan,
            scheduling_question_can_afford: this.data.monthlyPayment,
            scheduling_question_session_kind: this.data.sessionType,
            first_name: this.sessionContext.user?.person.firstName,
            last_name: this.sessionContext.user?.person.lastName,
            employee_id: this.sessionContext.currentEmployee?.id,
            user_id: this.sessionContext.user?.id,
            email: this.data.email
        };
        return Object.entries(answers).filter(([_, answer])=>answer) // Keep only truthy answers
        .map(([question, answer])=>`${question}: ${answer}.`).join('\n');
    }
    // Used to prefill data within the calendly widget
    get calendlyPrefillData() {
        return {
            firstName: this.sessionContext.user?.person.firstName,
            lastName: this.sessionContext.user?.person.lastName
        };
    }
    @action
    onCalendlyError() {
        this.calendlyError = true;
    }
    // TODO: Refactor to use a tracked function so we can add loading indicator for button,
    // prevent multiple submissions from button smashing, and give user more feedback once complete
    @action
    async submitSchedulingRequest() {
        const headers = {
            'x-api-key': ENV.apiKey,
            'tio-auth-token': this.sessionContext.session.data.authenticated.access_token,
            'Content-Type': 'application/json'
        };
        const url = `${ENV.apiHost}/coaching/schedule-loan`;
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                scheduleRequestContent: this.plainText
            }),
            headers: headers
        });
        if (!response.ok) {
            this.submissionError = true;
            return;
        }
        this.submissionError = false;
        // Scheduling is only needed for a phone session
        if (response.ok && !this.emailPreferred) {
            this.showSchedulingCalendar = true;
        } else {
            this.didSubmitSuccessfully = true;
        }
    }
    @action
    setSessionType(value: string) {
        this.emailPreferred = value === 'email';
    }
    @action
    validateForm(data: Record<string, unknown>) {
        const fieldsPresent = data.topic && data.repaymentGoal && data.studentLoanType && data.repaymentPlan && data.monthlyPayment && data.sessionType;
        if (!fieldsPresent) {
            this.submitEnabled = false;
            return;
        }
        if (data.sessionType === 'email') {
            try {
                string().nullable().required().email().validateSync(data.email);
            } catch (err) {
                this.submitEnabled = false;
                return;
            }
        }
        this.data = data;
        this.submitEnabled = true;
    }
    static{
        template_71dd7d03e05c4fb7b27855943c61ff5d(`
    {{#unless (or this.showSchedulingCalendar this.didSubmitSuccessfully)}}
      <Form @onChange={{this.validateForm}}>
        <VStack>
          <p>{{t "askjeni.schedule_time.answer_questions"}}</p>

          {{! Topic question section  }}
          <div>
            <Input @label={{t "askjeni.schedule_time.speak_about"}} @name="topic" />
            <div class="text-sm">
              {{t "askjeni.schedule_time.be_detailed"}}
            </div>
          </div>

          {{! Repayment goal question section }}
          <RadioGroup
            @name="repaymentGoal"
            @label={{t "askjeni.schedule_time.what_goal"}}
            @isRequired={{true}}
            as |Radio|
          >
            <Radio
              @value={{t "askjeni.schedule_time.goal_choice_1"}}
              @label={{t "askjeni.schedule_time.goal_choice_1"}}
            />
            <Radio
              @value={{t "askjeni.schedule_time.goal_choice_2"}}
              @label={{t "askjeni.schedule_time.goal_choice_2"}}
            />
          </RadioGroup>

          {{! Loan types question section }}
          <RadioGroup
            @name="studentLoanType"
            @label={{t "askjeni.schedule_time.what_have_you"}}
            @isRequired={{true}}
            as |Radio|
          >
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_1"}}
              @label={{t "askjeni.schedule_time.have_choice_1"}}
            />
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_2"}}
              @label={{t "askjeni.schedule_time.have_choice_2"}}
            />
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_3"}}
              @label={{t "askjeni.schedule_time.have_choice_3"}}
            />
            <Radio
              @value={{t "askjeni.schedule_time.have_choice_4"}}
              @label={{t "askjeni.schedule_time.have_choice_4"}}
            />
          </RadioGroup>

          {{! Payment plans question section }}
          <Select
            @name="repaymentPlan"
            @label={{t "askjeni.schedule_time.current_plan"}}
            @isClearable={{true}}
            @placeholder="Payment Method"
            @items={{this.paymentPlans}}
            @selectionMode="multiple"
          >
            <:item as |o|>
              <o.Item @key={{o.item}} @intent="default" @appearance="faded">
                {{o.item}}
              </o.Item>
            </:item>
          </Select>

          {{! Can afford question section }}
          <RadioGroup
            @name="monthlyPayment"
            @label={{t "askjeni.schedule_time.can_you_afford"}}
            @isRequired={{true}}
            as |Radio|
          >
            <Radio
              @value={{t "askjeni.schedule_time.yes"}}
              @label={{t "askjeni.schedule_time.yes"}}
            />
            <Radio
              @value={{t "askjeni.schedule_time.no"}}
              @label={{t "askjeni.schedule_time.no"}}
            />
          </RadioGroup>

          {{! Session kind question section }}
          <RadioGroup
            @name="sessionType"
            @label={{t "askjeni.schedule_time.kind_of_session"}}
            @isRequired={{true}}
            @onChange={{this.setSessionType}}
            as |Radio|
          >
            <Radio @value="phone" @label={{t "askjeni.schedule_time.phone"}} />
            <Radio @value="email" @label={{t "askjeni.schedule_time.email"}} />
          </RadioGroup>

          {{#if this.emailPreferred}}
            {{! Email prompt section  }}
            <Input @name="email" @label={{t "askjeni.schedule_time.enter_email"}} />
          {{/if}}

          <Divider class="my-9" />

          <div class="flex flex-row justify-between">
            <Button
              @appearance="minimal"
              class="p-10"
              {{on "click" (transitionTo "authenticated.repaying-student-debt.askjeni.index")}}
            >
              {{t "cancel"}}
            </Button>
            <Button
              @intent="primary"
              class="p-10"
              {{on "click" this.submitSchedulingRequest}}
              disabled={{not this.submitEnabled}}
            >
              {{t "submit"}}
            </Button>
          </div>
        </VStack>
      </Form>
    {{/unless}}

    {{#if this.showSchedulingCalendar}}
      {{#if this.calendlyError}}
        <TioAlert @type="error" @allowDismiss={{false}}>
          <:body>
            <p class="font-bold">{{t "askjeni.schedule_time.calendly_error"}}</p>
          </:body>
        </TioAlert>
      {{/if}}
      <div
        id="calendly-container"
        class="h-[756px]"
        {{embedCalendly
          "https://calendly.com/tiocoaches"
          this.calendlyPrefillData
          this.onCalendlyError
        }}
      />
    {{/if}}

    {{#if this.submissionError}}
      <TioAlert @type="error" @allowDismiss={{false}} class="mb-6">
        <:body>
          <p class="font-bold">{{t "askjeni.schedule_time.submission_error"}}</p>
        </:body>
      </TioAlert>
    {{/if}}
    {{#if this.didSubmitSuccessfully}}
      <TioAlert @type="success" @allowDismiss={{false}}>
        <:body>
          <p class="font-bold">{{t "askjeni.schedule_time.submission_success"}}</p>
        </:body>
      </TioAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
