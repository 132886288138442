import { template as template_848a1fb25bf54301882e3c835e4b3748 } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import CardInfo from '../card-info';
import Component from '@glimmer/component';
import NumberBadge from '../todo/number-badge';
import tippy from 'ember-tippy/modifiers/tippy';
import Todo from '../todo';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type PartnerService from 'tio-employee/services/partner';
import type PslfForm from 'tio-common/models/pslf-form';
import type PslfProfileModel from 'tio-common/models/pslf-profile';
import InfoCircle from 'ember-static-heroicons/components/outline-24/information-circle';
export interface PslfDashboardActionsFormStatusTodoSignature {
    Args: {
        pslfProfile: PslfProfileModel;
        itemNo: string;
    };
}
export default class PslfDashboardActionsFormStatusTodoComponent extends Component<PslfDashboardActionsFormStatusTodoSignature> {
    @service
    partner: PartnerService;
    get isPriorEmployerEnabled() {
        return this.partner.isEnabledPriorEmployerPslf;
    }
    get forms() {
        return this.args.pslfProfile.pslfForms || [];
    }
    get submittedForms() {
        const submittedStates = [
            'submitted',
            're_submitted'
        ];
        return this.forms.slice().filter((form: PslfForm)=>submittedStates.includes(form.status));
    }
    get signedForms() {
        const signedStates = [
            'borrower_signed',
            'employer_signed',
            'awaiting_submit',
            'submit_pending',
            'submit_errored',
            'submitted',
            're_submitted',
            're_submit_pending',
            're_submit_errored'
        ];
        return this.forms.slice().filter((form: PslfForm)=>signedStates.includes(form.status));
    }
    get hasSignedForms() {
        return !!this.signedForms.length || !!this.submittedForms.length;
    }
    static{
        template_848a1fb25bf54301882e3c835e4b3748(`
    <Todo
      @title={{t "pslf_dashboard.actions.pslf_form_status"}}
      @isComplete={{this.hasSignedForms}}
    >
      <:leftHeader>
        <NumberBadge @number={{@itemNo}} />
      </:leftHeader>
      <:body>
        <div class="grid grid-cols-2 gap-1">
          <CardInfo
            @icon="note_alt"
            @text={{t "pslf_dashboard.actions.forms_i_have_signed"}}
            @data="{{this.signedForms.length}}"
          />
          <CardInfo
            @icon="assignment_turned_in"
            @text={{t "pslf_dashboard.actions.forms_submitted_dept_of_ed"}}
            @data="{{this.submittedForms.length}}"
          />
        </div>
      </:body>
      <:actions>
        {{#if this.hasSignedForms}}
          <Button @intent="primary" {{on "click" (transitionTo "authenticated.pslf.my-documents")}}>
            {{t "pslf_dashboard.actions.view_details"}}
          </Button>
          <Button
            data-test-pslf-start-another-new-form
            {{on "click" (transitionTo "authenticated.pslf.dashboard.start-new")}}
            @appearance="outlined"
          >
            <div class="flex items-center">
              {{t "pslf_dashboard.actions.start_another_form"}}
              {{#if this.isPriorEmployerEnabled}}
                <InfoCircle
                  class="w-5 ml-1"
                  {{! TODO: this doesn't really work on mobile}}
                  {{tippy
                    (t "pslf_dashboard.actions.if_you_have_a_prior_employer")
                    placement="top"
                  }}
                />
              {{/if}}
            </div>
          </Button>
        {{else}}
          <Button
            data-test-pslf-start-new-form
            @intent="primary"
            {{on "click" (transitionTo "authenticated.pslf.dashboard.start-new")}}
          >
            {{t "pslf_dashboard.actions.start_new_form"}}
          </Button>
        {{/if}}
      </:actions>
    </Todo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
