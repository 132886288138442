import { template as template_5837f3305808474c9e81b10ba88b735e } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import CompanySettingModel from 'tio-common/models/company-setting';
import Component from '@glimmer/component';
import type { IntlService } from 'ember-intl';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
interface AuthRegisterSsoComponentSignature {
    Args: {
        settings: CompanySettingModel;
    };
}
export default class AuthRegisterSso extends Component<AuthRegisterSsoComponentSignature> {
    @service
    intl: IntlService;
    get registerPageCopy() {
        return this.args.settings.registerPageCopy || this.intl.t('login.register_welcome_sso');
    }
    get ssoUrl(): string {
        return this.args.settings.ssoAuthUrl;
    }
    get idProvider() {
        return this.args.settings?.idProvider || '';
    }
    @action
    goToSsoUrl() {
        window.location.href = this.ssoUrl;
    }
    static{
        template_5837f3305808474c9e81b10ba88b735e(`
    <div class="flex flex-col items-center p-5">
      <h1 class="text-lg mt-2 mb-6">
        {{this.registerPageCopy}}
      </h1>
      <Button @intent="primary" {{on "click" this.goToSsoUrl}}>
        {{t "login.sign_in_with" idProvider=this.idProvider}}
      </Button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
