import { template as template_d0c3b701324f4fde8dc6a03c3b85e1a3 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import ForgivenessEstimatorResultInstructions from 'tio-employee/components/forgiveness-estimator/result-instructions';
import type RecommendationModel from 'tio-common/models/recommendation';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
interface S {
    Args: {
        model: RecommendationModel;
    };
}
export default RouteTemplate<S>(template_d0c3b701324f4fde8dc6a03c3b85e1a3(`
    {{pageTitle (t "recommendations_estimator.next_steps")}}
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.pslf.dashboard.index" @label="Dashboard" />
      <b.crumb
        @route="authenticated.pslf.dashboard.forgiveness-estimator.index"
        @label="Forgiveness Estimator"
      />
      <b.crumb
        @route="authenticated.pslf.dashboard.forgiveness-estimator.results.index"
        @model={{@model}}
        @label="Results"
      />
      <b.crumb
        @route="authenticated.pslf.dashboard.forgiveness-estimator.results.next-steps"
        @label="Next Steps"
      />
    </TioPageBreadcrumbs>

    <ForgivenessEstimatorResultInstructions @recommendation={{@model}} />

    <div class="flex justify-center">
      <Button {{on "click" (transitionTo "authenticated.pslf.dashboard")}} class="w-fit m-4">
        {{t "recommendations_estimator.go_to_dashboard"}}
      </Button>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
