import { template as template_e3c32d68132846579505d617b9000ec8 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import XToggle from 'ember-toggle/components/x-toggle';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
export interface ShouldIRefinanceComponentSignature {
    Args: {
        recommendation: (arg0: boolean) => void;
    };
}
class ShouldIRefinanceComponent extends Component<ShouldIRefinanceComponentSignature> {
    choice1 = false;
    choice2 = false;
    choice3 = false;
    choice4 = false;
    choice5 = false;
    choice6 = false;
    choice7 = false;
    choice8 = false;
    @action
    switchChanged() {
        /* There are some weird timing issues with x-toggle.
       For now the setTimeout is a workaround. Long term figure out
       if component can play better with tracked or make our own. */ setTimeout(()=>{
            let match = [
                true,
                true,
                true,
                true,
                false,
                false,
                false,
                false
            ];
            let current = [];
            for(let i = 1; i < 9; i++){
                // @ts-expect-error we should not be accessing properties dynamically on this.
                current.push(this[`choice${i}`]);
            }
            if (current.toString() === match.toString()) {
                return this.args?.recommendation(true);
            }
            return this.args?.recommendation(false);
        }, 100);
    }
    static{
        template_e3c32d68132846579505d617b9000ec8(`
    <div class="grid grid-cols-12 gap-4">
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice1}}
          @onToggle={{fn (mut this.choice1)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_good_credit_score"}}
        </h4>
        <p>{{t "wellness.refinancing_good_credit_score_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice2}}
          @onToggle={{fn (mut this.choice2)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">{{t "wellness.refinancing_stable_income"}}</h4>
        <p>{{t "wellness.refinancing_stable_income_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice3}}
          @onToggle={{fn (mut this.choice3)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_loan_balance_at_least"}}
        </h4>
        <p>{{t "wellness.refinancing_loan_balance_at_least_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice4}}
          @onToggle={{fn (mut this.choice4)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_interest_be_lower"}}
        </h4>
        <p>{{t "wellness.refinancing_interest_be_lower_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice5}}
          @onToggle={{fn (mut this.choice5)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_have_federal_loans_trouble"}}
        </h4>
        <p>{{t "wellness.refinancing_have_federal_loans_trouble_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice6}}
          @onToggle={{fn (mut this.choice6)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_eligible_loan_forgiveness"}}
        </h4>
        <p>{{t "wellness.refinancing_eligible_loan_forgiveness_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice7}}
          @onToggle={{fn (mut this.choice7)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">
          {{t "wellness.refinancing_interest_rate_comfortable"}}
        </h4>
        <p>{{t "wellness.refinancing_interest_rate_comfortable_description"}}</p>
      </div>
      <div class="mt-4 col-span-4 sm:col-span-2">
        <XToggle
          @theme="material"
          @size="small"
          @value={{this.choice8}}
          @onToggle={{fn (mut this.choice8)}}
          @showLabels={{true}}
          @offLabel={{t "no"}}
          @onLabel={{t "yes"}}
          {{on "click" this.switchChanged}}
        />
      </div>
      <div class="col-span-8 mt-4 sm:col-span-10">
        <h4 class="font-semibold">{{t "wellness.refinancing_back_to_school"}}</h4>
        <p>{{t "wellness.refinancing_back_to_school_description"}}</p>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ShouldIRefinanceComponent;
