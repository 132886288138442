import { template as template_cf62f6f3152b4f39b9e93c11203d7953 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import QuestionsGlossary from 'tio-employee/components/questions/glossary';
import { Header, Section, VStack } from 'tio-ui/components/layout';
export default RouteTemplate(template_cf62f6f3152b4f39b9e93c11203d7953(`
    <VStack>
      <Header>{{t "questions_you_have.glossary.glossary"}}</Header>
      <Section>
        <:header>{{t "questions_you_have.glossary.glossary_subtitle"}}</:header>
        <:body>
          <VStack>
            <QuestionsGlossary />
          </VStack>
        </:body>
      </Section>
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
