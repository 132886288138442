import { template as template_b53bfddf5fad428b88d98d7bf1a73a7d } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import Tile from '../tile';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
const DashboardWidgetsPublicServiceLoanForgivenessComponent: TOC = template_b53bfddf5fad428b88d98d7bf1a73a7d(`
  <LinkTo @route="authenticated.pslf.index">
    <Tile @headerText={{t "pslf.default_full"}}>
      <:description>
        {{t "pslf.dashboard.widgets.are_you_interested_in_pslf"}}
      </:description>
      <:image>
        {{svgJar
          "dept-of-ed"
          width="100%"
          height="100%"
          role="img"
          desc=(t "svg.dept_of_ed")
          class="lg:max-h-56 xl:max-h-full"
        }}
      </:image>
    </Tile>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DashboardWidgetsPublicServiceLoanForgivenessComponent;
