import { template as template_c3f38ab8f2ed4c5489c8ce81f1c1eed9 } from "@ember/template-compiler";
import pageTitle from 'ember-page-title/helpers/page-title';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { service } from '@ember/service';
import { action } from '@ember/object';
import type PreRegisterService from '../services/pre-register';
import type RouterService from '@ember/routing/router';
import type SessionService from '../services/session';
import { t } from 'ember-intl';
import TioFooter from 'tio-common/components/tio/footer';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
class WelcomeRoute extends Component {
    @service
    preRegister: PreRegisterService;
    @service
    router: RouterService;
    @service
    session: SessionService;
    get settings() {
        return this.preRegister.settings?.landingPage;
    }
    get title() {
        return this.settings?.title;
    }
    get content() {
        return this.settings?.html;
    }
    get buttonLabel() {
        return this.settings?.buttonText;
    }
    get buttonUrl() {
        return this.settings?.externalLoginUrl;
    }
    @action
    async gotoLogin() {
        this.session?.setTransientValue?.('landingPageViewed', true);
        this.router.transitionTo('login');
    }
    static{
        template_c3f38ab8f2ed4c5489c8ce81f1c1eed9(`
    {{pageTitle (t "login.page_title")}}

    <div class="w-screen h-screen flex flex-col overflow-y-auto">
      <div class="relative lg:min-h-screen flex flex-col">
        <div class="absolute bottom-0 w-screen h-[20vh] lg:h-[30vh] bg-midnight" />

        <div class="lg:h-screen flex flex-col lg:flex-row justify-around pt-8 lg:p-12 z-5">
          <div class="lg:w-[30vw]">
            <div class="bg-white rounded-lg pb-16">
              <h1 class="font-semibold text-3xl text-gray-600 text-center">
                {{this.title}}
              </h1>

              <div class="text-xl m-12 welcome-html">
                {{! template-lint-disable no-triple-curlies }}
                {{{this.content}}}
              </div>

              {{! If buttonUrl is defined in the landing page settings, it is
              used; otherwise gotoLogin is called when clicked }}
              {{#if this.buttonUrl}}
                <a href={{this.buttonUrl}} class="cursor-pointer text-ocean-600 underline text-md">
                  {{this.buttonLabel}}
                </a>
              {{else}}
                <Button class="mx-auto" {{on "click" this.gotoLogin}}>
                  {{this.buttonLabel}}
                </Button>
              {{/if}}
            </div>
          </div>

          <div class="lg:w-[40vw] p-12 lg:p-0">
            <img
              class="lg:absolute lg:bottom-[20vh] lg:right-[10vw] lg:max-w-[40vw] max-h-[80vh]"
              src="/assets/images/meeting.svg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div class="pb-20 mx-6 lg:mx-12">
        <TioFooter />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(WelcomeRoute);
