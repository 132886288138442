import { template as template_a88021bbfb144d4db3d0d41f3dfae899 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import type { TOC } from '@ember/component/template-only';
import { gt } from 'tio-ui/utilities';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
import MaterialIcon from 'tio-common/components/material-icon';
import formatCentsToDollars from 'tio-common/helpers/format-cents-to-dollars';
import type { SyfInsightsFourOhOneKRouteModel } from 'tio-employee/routes/authenticated/syf/insights/four-oh-one-k';
interface SyfInsightsFourOhOneKRouteSignature {
    Args: {
        model: SyfInsightsFourOhOneKRouteModel;
    };
}
interface NumberCircleSignature {
    Args: {
        number: string;
    };
    Element: HTMLDivElement;
}
const NumberCircle: TOC<NumberCircleSignature> = template_a88021bbfb144d4db3d0d41f3dfae899(`
  <div
    class="shrink-0 bg-white border-2 border-violet-800 text-violet-800 rounded-full leading-10 h-9 w-9 inline-flex justify-center items-center"
  >
    <p class="text-xl font-semibold">
      {{@number}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
// eslint-disable-next-line ember/no-empty-glimmer-component-classes
class SyfInsightsFourOhOneKRouteComponent extends Component<SyfInsightsFourOhOneKRouteSignature> {
    static{
        template_a88021bbfb144d4db3d0d41f3dfae899(`
    <TioPageBreadcrumbs class="mb-4" as |b|>
      <b.crumb @route="authenticated.syf.dashboard" @label="Dashboard" />
      <b.crumb @route="authenticated.syf.insights.four-oh-one-k" @label="401k" />
    </TioPageBreadcrumbs>
    <div class="mx-12">
      <h1 class="text-xl text-violet-800 font-semibold">{{t
          "syf.insights.four_oh_one_k.heading"
        }}</h1>
      <div class="grid grid-cols-3">
        <div>
          <h2 class="text-lg mt-4">{{t "syf.insights.four_oh_one_k.insight.heading"}}</h2>
          <div class="flex mt-4">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{t
                "syf.insights.four_oh_one_k.insight.one"
                salary=(formatCentsToDollars @model.matchParticipant.reportedSalary)
                match=(formatCentsToDollars @model.insights.maxPossibleMatch)
                match_statement=@model.matchPlan.matchStatement
                htmlSafe=true
              }}
            </div>
          </div>
          <div class="flex mt-4">
            <MaterialIcon @icon="check" class="text-green-700" />
            <div class="ml-4">
              {{#if (gt @model.insights.defermentDeltaPerMonth 0)}}
                {{t
                  "syf.insights.four_oh_one_k.insight.two"
                  monthly=(formatCentsToDollars @model.insights.defermentDeltaPerMonth)
                  htmlSafe=true
                }}
              {{else}}
                {{t "syf.insights.four_oh_one_k.insight.two_no_action"}}
              {{/if}}
            </div>
          </div>
        </div>
        <div class="col-span-2 ml-12">
          <h2 class="text-lg mt-4">{{t "syf.insights.four_oh_one_k.match.heading"}}</h2>
          <div class="my-4 flex">
            <NumberCircle @number="1" />
            <div class="ml-4">
              {{t
                "syf.insights.four_oh_one_k.match.one"
                monthly=(formatCentsToDollars @model.matchParticipant.reportedMonthlyPayment)
                annually=(formatCentsToDollars @model.insights.yearlyMatch)
                htmlSafe=true
              }}
            </div>
          </div>
          <div class="my-4 flex">
            <NumberCircle @number="2" />
            <div class="ml-4">
              {{#if (gt @model.insights.defermentDeltaPerMonth 0)}}
                {{t
                  "syf.insights.four_oh_one_k.match.two"
                  monthly=(formatCentsToDollars @model.insights.defermentDeltaPerMonth)
                  annually=(formatCentsToDollars @model.insights.defermentDelta)
                  annual_unlock=(formatCentsToDollars @model.insights.matchDelta)
                  htmlSafe=true
                }}
              {{else}}
                {{t "syf.insights.four_oh_one_k.match.two_no_action"}}
              {{/if}}
            </div>
          </div>
          <div class="my-4 flex">
            <MaterialIcon @icon="lock_open" />
            <div class="ml-4">
              {{t
                "syf.insights.four_oh_one_k.match.three"
                maximum=(formatCentsToDollars @model.insights.maxPossibleMatch)
                htmlSafe=true
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfInsightsFourOhOneKRouteComponent);
