import { template as template_6a8d0867b4844c53baaa3d5a582f2b7c } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { Modal } from 'tio-ui/components/modal';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
export interface ErrorModalComponentSignature {
    Args: {
        isOpen: boolean;
        onSubmit: () => void;
        renderInPlace?: boolean;
    };
}
const ErrorModalComponent: TOC<ErrorModalComponentSignature> = template_6a8d0867b4844c53baaa3d5a582f2b7c(`
  <Modal
    data-test-error-modal
    @isOpen={{@isOpen}}
    @allowCloseButton={{false}}
    @closeOnEscapeKey={{false}}
    @closeOnOutsideClick={{false}}
    @renderInPlace={{@renderInPlace}}
    class="min-h-64"
    @backdrop="faded"
    as |m|
  >
    <m.Header>{{t "login.reset_password_error"}}</m.Header>
    <m.Body>{{t "login.reset_password_error_body"}}</m.Body>
    <m.Footer>
      <Button {{on "click" @onSubmit}} @intent="primary">
        {{t "login.back_to_login"}}
      </Button>
    </m.Footer>
  </Modal>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ErrorModalComponent;
