import { template as template_bd110f682a174f4792d6c59efe57f772 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import NavGroupsAdminPslf from './nav-groups/admin/pslf';
interface AppSidebarPslfAdminNavSignature {
    Args: {
        nav: unknown;
    };
}
const AppSidebarPslfAdminNavComponent: TOC<AppSidebarPslfAdminNavSignature> = template_bd110f682a174f4792d6c59efe57f772(`
  {{#let @nav as |nav|}}
    <NavGroupsAdminPslf @nav={{nav}} />
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarPslfAdminNavComponent;
