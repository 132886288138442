import { template as template_62313c6ba77a4e5a926d3a8dd7891f9a } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import CardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import ProgramReadonlyField from 'tio-common/components/tuition-assistance/program-readonly-field';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import { Button } from 'tio-ui/components/buttons';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
const employeeFields = [
    'NAME',
    'EMPLOYEE_ID',
    'FT_PT_OTHER',
    'EMPLOYEE_EMAIL'
];
export interface S {
    Args: {
        programInstance: TasProgramInstanceModel;
    };
    Element: HTMLDivElement;
}
const FormsPreApprovalApplicationReviewEmployeeReviewCard: TOC<S> = template_62313c6ba77a4e5a926d3a8dd7891f9a(`
  <CardsGeneric ...attributes>
    <:headerTitle>
      {{t "tuition_assistance.preapproval_app.employee_info"}}
    </:headerTitle>
    <:body>
      <dl class="grid grid-cols-4 gap-2 my-4 w-full">
        <ProgramReadonlyField
          @name="APPLICATION_ID"
          @programTemplate={{@programInstance.tasProgramTemplate}}
          @fields={{@programInstance.fields}}
          as |field|
        >
          <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
          <dd class="col-span-2 font-semibold">
            {{@programInstance.id}}
          </dd>
        </ProgramReadonlyField>
        {{#each employeeFields as |fieldName|}}
          <ProgramReadonlyField
            {{! @glint-expect-error: need better type on field }}
            @name={{fieldName}}
            @programTemplate={{@programInstance.tasProgramTemplate}}
            @fields={{@programInstance.fields}}
            as |field|
          >
            <dt class="col-span-2 text-sm">{{field.programLabel}}</dt>
            <dd class="col-span-2 font-semibold break-words">
              {{! @glint-expect-error }}
              {{field.value}}
            </dd>
          </ProgramReadonlyField>
        {{/each}}
      </dl>
    </:body>
    <:footer>
      <div class="flex justify-end">
        <Button
          @appearance="outlined"
          @intent="primary"
          {{on
            "click"
            (transitionTo
              "authenticated.tuition-assistance.programs.instance.apply.employee-information"
              @programInstance.id
            )
          }}
          class="mx-1"
          title={{t "common.edit"}}
        >
          <Pencil class="w-5" />
        </Button>
      </div>
    </:footer>
  </CardsGeneric>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FormsPreApprovalApplicationReviewEmployeeReviewCard;
