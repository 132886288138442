import { template as template_831842715ccc42bba3a0606c33b8eda4 } from "@ember/template-compiler";
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import PslfSignFormPage from 'tio-employee/components/pslf/sign-form-page';
import RouteTemplate from 'ember-route-template';
import type { IntlService } from 'ember-intl';
import type PslfAdminFormSignController from 'tio-employee/controllers/authenticated/admin/pslf/form/sign';
import type PslfFormModel from 'tio-common/models/pslf-form';
import { Header, Section, VStack } from 'tio-ui/components/layout';
export interface PslfFormSignRouteSignature {
    Args: {
        model: PslfFormModel;
        controller: PslfAdminFormSignController;
    };
}
class AdminPslfFormSignRoute extends Component<PslfFormSignRouteSignature> {
    @service
    intl: IntlService;
    get borrowerName() {
        return this.args.model.borrowerFields['borrower-name'];
    }
    get isBorrower() {
        return this.args.controller.signerType === 'borrower';
    }
    get alreadySigned() {
        const status = this.args.model.status;
        const isSigned = this.isBorrower ? ![
            'created',
            'pending'
        ].includes(status) : ![
            'created',
            'borrower_signed'
        ].includes(status);
        return isSigned;
    }
    get employerMessage() {
        return this.intl.t('pslf.complete_pslf.done_employer_subheader', {
            date: this.intl.formatDate(this.args.model.borrowerCompletedAt),
            name: this.borrowerName
        });
    }
    get continueLink() {
        return this.isBorrower ? 'authenticated.pslf.dashboard.index' : 'authenticated.admin.pslf';
    }
    static{
        template_831842715ccc42bba3a0606c33b8eda4(`
    <VStack>
      <Header>{{t "pslf.default_full"}}</Header>
      <Section>
        <:body>
          <PslfSignFormPage @form={{@model}} @signer={{@controller.signerType}} />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AdminPslfFormSignRoute);
