import { template as template_1046ebd144c8410f961d6f0801db6b88 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_1046ebd144c8410f961d6f0801db6b88(`
    {{pageTitle (t "results")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
