import { template as template_a59d828c61b64874ba898a9a86f4ec26 } from "@ember/template-compiler";
import type { TemplateOnlyComponent } from '@ember/component/template-only';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import { t } from 'ember-intl';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
export interface EnrollmentCompleteSignature {
    Element: HTMLDivElement;
}
const EnrollmentCompleteComponent: TemplateOnlyComponent<EnrollmentCompleteSignature> = template_a59d828c61b64874ba898a9a86f4ec26(`
  {{! template-lint-disable no-bare-strings }}
  <div class="flex justify-center my-4">
    {{svgJar "enrollment-complete" width="150px" role="img" desc=(t "svg.enrollment_complete")}}
  </div>
  <h1 class="text-4xl mb-6 text-center">{{t "syf.enrollment.submitted.enrollment_complete"}}</h1>
  <section class="my-10">
    <div class="grid md:grid-cols-6 mx-8">
      <h3 class="font-semibold mb-4 md:col-start-2 md:col-span-3">
        {{t "syf.enrollment.submitted.what_happens_now"}}
      </h3>
      <ul class="md:col-start-2 md:col-span-4 list-disc ml-8 text-sm md:text-base">
        <li class="my-1.5">
          {{t "syf.enrollment.submitted.you_are_now_enrolled"}}
        </li>
        <li class="my-1.5">
          {{t "syf.enrollment.submitted.your_benefit_start_date"}}
          <b>January 1, 2024</b></li>
      </ul>
    </div>
    <div class="flex justify-center mt-16">
      {{! TODO: Verify button route }}
      <Button {{on "click" (transitionTo "authenticated.syf.post-enrollment")}} class="m-8 w-48">
        {{t "done"}}
      </Button>
    </div>
  </section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EnrollmentCompleteComponent;
