import { template as template_af04265c48b244baac749a954dc2bd30 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { concat } from '@ember/helper';
import { fn } from '@ember/helper';
import { gte } from 'tio-ui/utilities';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import { trackedFunction } from 'reactiveweb/function';
import AppContentCard from 'tio-common/components/app/content-card';
import checkBulletListsAreSame from 'tio-employee/utils/recommendations/check-bullet-lists-are-same';
import Component from '@glimmer/component';
import ForgivenessEstimatorEstimatorSummaryDialog from 'tio-employee/components/forgiveness-estimator/estimator-summary-dialog';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import screenIs from 'tio-common/helpers/screen-is';
import TioDownloadButton from 'tio-common/components/tio/download-button';
import { Divider } from 'tio-ui/components/utilities';
import TioPdfPrintButton from 'tio-common/components/tio/pdf/print-button';
import type IntlService from 'ember-intl/services/intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import { Button } from 'tio-ui/components/buttons';
export interface RecommendationsResultsResultsDetailsSignature {
    Args: {
        programType: keyof typeof PROGRAM_ATTRIBUTES;
        recommendation: RecommendationModel;
        hideHeaderSection?: boolean;
    };
}
export const PROGRAM_ATTRIBUTES = {
    pslf: {
        headerTitleKey: 'recommendations_estimator.forgiveness_summary',
        linkBackToEstimator: 'forgiveness-estimator',
        routeToProgram: 'pslf.dashboard',
        linkToNextSteps: 'forgiveness-estimator.results.next-steps'
    },
    sf: {
        headerTitleKey: 'common.strategy_finder.default',
        linkBackToEstimator: 'questionnaire',
        routeToProgram: 'repaying-student-debt.repayment-strategy-finder.dashboard',
        linkToNextSteps: 'questionnaire.results.next-steps'
    },
    idr: {
        headerTitleKey: 'recommendations_estimator.monthly_payment_and_forgiveness_results',
        linkBackToEstimator: 'forgiveness-estimator',
        routeToProgram: 'idr.dashboard',
        linkToNextSteps: 'forgiveness-estimator.results.next-steps'
    }
};
type verticalHeaderRow = {
    title: string;
    subtitle?: string;
    dataKey: string;
    formatter: string;
    footnote?: string;
    asterisk?: boolean;
};
export default class RecommendationsResultsResultsDetailsComponent extends Component<RecommendationsResultsResultsDetailsSignature> {
    @service
    intl: IntlService;
    @service
    sessionContext: SessionContextService;
    @service
    store: StoreService;
    @service
    strategyFinder: StrategyFinderService;
    @tracked
    showEstimatorSummaryDialog = false;
    @tracked
    showBestHeaderTitle = true;
    @tracked
    showCurrentHeaderTitle = true;
    get currentAttributes() {
        return PROGRAM_ATTRIBUTES[this.args.programType] || {};
    }
    get headerTitle() {
        return this.intl.t(this.currentAttributes.headerTitleKey);
    }
    get routeToProgram() {
        return `authenticated.${this.currentAttributes.routeToProgram}`;
    }
    get linkBackToEstimator() {
        return `${this.routeToProgram}.${this.currentAttributes.linkBackToEstimator}`;
    }
    get linkToNextSteps() {
        return `${this.routeToProgram}.${this.currentAttributes.linkToNextSteps}`;
    }
    get estimatedMonthlyPayment() {
        return this.args.recommendation?.estimatedMonthlyPayment;
    }
    get estimatedForgivenessAmount() {
        return this.args.recommendation?.estimatedForgivenessAmount || 0;
    }
    get estimatedForgivenessDate() {
        return this.args.recommendation?.estimatedForgivenessDate;
    }
    get currentPlanSummary() {
        return this.args.recommendation?.formattedCurrentPlanSummary;
    }
    get bestPlanSummary() {
        return this.args.recommendation?.formattedBestPlanSummary;
    }
    get estimatedMonthlySavings() {
        return this.args.recommendation?.estimatedMonthlySavings;
    }
    get verticalHeaderRows(): verticalHeaderRow[] {
        return [
            {
                title: this.intl.t('recommendations_estimator.estimated_total_to_pay'),
                dataKey: 'payoffAmount',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.years_left_to_repay'),
                dataKey: 'termMonths',
                formatter: 'formatTermMonths'
            },
            {
                title: this.intl.t('recommendations_estimator.monthly_payment'),
                dataKey: 'monthlyPayments',
                formatter: 'formatMoney',
                asterisk: true
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_total_interest_paid'),
                dataKey: 'totalInterestPaid',
                formatter: 'formatMoney'
            },
            {
                title: this.intl.t('recommendations_estimator.estimated_amount_forgiven'),
                subtitle: this.intl.t('recommendations_estimator.estimated_amount_forgiven_includes_interest'),
                dataKey: 'remLoanBalance',
                formatter: 'formatMoney'
            }
        ];
    }
    get bestHeaderColumn() {
        return [
            this.bestPlanColumn
        ];
    }
    get currentHeaderColumn() {
        return [
            this.currentPlanColumn
        ];
    }
    get headerTranslation() {
        return this.args.programType === 'idr' ? this.intl.t('recommendations_estimator.your_income_driven_repayment_option') : this.intl.t('recommendations_estimator.top_strategy');
    }
    get workflowName() {
        return this.args.programType === 'sf' ? this.intl.t('common.strategy_finder.default') : this.intl.t('recommendations_estimator.forgiveness_estimator');
    }
    get bestPlanColumn() {
        const bestPlanHeader = this.args.recommendation?.bestPlanTableHeadings || [];
        if (bestPlanHeader.length > 1) {
            this.formatHeader('best', bestPlanHeader);
            if (!this.showBestHeaderTitle) {
                const bestPlanHeaderNoDuplicates = bestPlanHeader.slice(0, 1);
                return {
                    title: this.headerTranslation,
                    headers: bestPlanHeaderNoDuplicates
                };
            }
        }
        return {
            title: this.headerTranslation,
            headers: bestPlanHeader
        };
    }
    get currentPlanColumn() {
        const currentPlanHeader = this.args.recommendation?.currentPlanTableHeadings || [];
        if (currentPlanHeader.length > 1) {
            this.formatHeader('current', currentPlanHeader);
            if (!this.showCurrentHeaderTitle) {
                const currentPlanHeaderNoDuplicates = currentPlanHeader.slice(0, 1);
                return {
                    title: this.intl.t('recommendations_estimator.your_current_plan'),
                    headers: currentPlanHeaderNoDuplicates
                };
            }
        }
        return {
            title: this.intl.t('recommendations_estimator.your_current_plan'),
            headers: currentPlanHeader
        };
    }
    get tableRowData() {
        return this.verticalHeaderRows.map((row)=>{
            // @ts-expect-error: why would the getter return null?
            const col1Value = this.bestPlanSummary[row.dataKey];
            // @ts-expect-error: why would the getter return null?
            const col2Value = this.currentPlanSummary[row.dataKey];
            return {
                title: row.title,
                subtitle: row.subtitle,
                col1Data: this.formatData(row.formatter, col1Value),
                col2Data: this.formatData(row.formatter, col2Value),
                asterisk: row.asterisk,
                footnote: row.footnote
            };
        });
    }
    get bestPlanSummaryCard() {
        return this.verticalHeaderRows.map((row)=>{
            // @ts-expect-error: why would the getter return null?
            const planText = this.bestPlanSummary[row.dataKey];
            return {
                title: row.title,
                subtitle: row.subtitle,
                footnote: row.footnote,
                asterisk: row.asterisk,
                planText: this.formatData(row.formatter, planText)
            };
        });
    }
    get currentPlanSummaryCard() {
        return this.verticalHeaderRows.map((row)=>{
            // @ts-expect-error: why would the getter return null?
            const planText = this.currentPlanSummary[row.dataKey];
            return {
                title: row.title,
                subtitle: row.subtitle,
                planText: this.formatData(row.formatter, planText)
            };
        });
    }
    personState = trackedFunction(this, async ()=>{
        const { user } = this.sessionContext;
        const person = await user?.person;
        if (!person) {
            return;
        }
        // If this has data, we've already loaded what we need into the store
        if (person.allActiveAccountLoans.length) {
            return person;
        }
        const personWithAccountsAndLoans = await this.store.findRecord('person', person.id);
        return personWithAccountsAndLoans;
    });
    get firstName() {
        return this.personState.value?.firstName;
    }
    get showReducedResultCard() {
        return this.args.hideHeaderSection ? false : true;
    }
    get formattedDate() {
        return this.formatDate(this.args.recommendation.createdAt);
    }
    @action
    formatData(formatFunctionName: string, data: unknown) {
        // @ts-expect-error: we should access this like this.
        return this[formatFunctionName]?.(data) || data;
    }
    @action
    formatMoney(centsRange = []) {
        return this.strategyFinder.formatCentsRange(centsRange);
    }
    @action
    formatTermMonths(monthsRange = []) {
        return this.strategyFinder.formatMonthsRange(monthsRange);
    }
    @action
    formatHeader(headerType: string, content: object[]) {
        // @ts-expect-error: how do we unify bullet list with type returned from server?
        if (headerType === 'current') this.showCurrentHeaderTitle = !checkBulletListsAreSame(content);
        // @ts-expect-error: how do we unify bullet list with type returned from server?
        if (headerType === 'best') this.showBestHeaderTitle = !checkBulletListsAreSame(content);
    }
    @action
    formatDate(date: string) {
        return this.strategyFinder.formatDateRange([
            date
        ]);
    }
    static{
        template_af04265c48b244baac749a954dc2bd30(`
    <VStack>
      <Header>{{this.headerTitle}}</Header>
      {{#if @recommendation.hasErrorsInCalcs}}
        <div class="m-2">
          {{t "pslf_dashboard.results.error"}}
          <LinkTo @route={{this.linkBackToEstimator}} class="tio-copy">
            {{this.workflowName}}
          </LinkTo>
          {{concat ". " (t "pslf_dashboard.results.thank_you")}}
        </div>
      {{else}}
        {{#if this.showReducedResultCard}}
          <p class="text-sm font-semibold">
            {{this.firstName}},
            {{t "recommendations_estimator.based_on_the_info_1"}}
            <button
              class="text-ocean-600 font-semibold underline"
              type="button"
              {{on "click" (fn (mut this.showEstimatorSummaryDialog) true)}}
            >
              {{t "recommendations_estimator.information"}}
            </button>
            {{t "recommendations_estimator.based_on_the_info_2"}}
          </p>

          <Section class="!bg-white md:max-w-fit">
            <:body>
              <div class="grid grid-rows-2 grid-flow-col gap-2 place-items-center">
                <h3 class="font-semibold md:text-xs text-tio-gray-700 ml-2 text-center">
                  {{t "recommendations_estimator.monthly_payment_card" htmlSafe=true}}
                </h3>
                <p class="font-bold text-midnight ml-2 text-lg">
                  {{#if (gte this.estimatedMonthlyPayment 0)}}
                    {{safeFormatNumber
                      this.estimatedMonthlyPayment
                      style="currency"
                      currency="USD"
                      maximumFractionDigits=0
                    }}*
                  {{/if}}
                </p>
                <h3
                  class="font-semibold text-tio-gray-700 ml-6 text-center md:text-left md:text-xs"
                >
                  {{t "recommendations_estimator.loan_forgiveness_card" htmlSafe=true}}
                </h3>
                <p class="font-bold text-midnight ml-6 text-lg">
                  {{#if (gte this.estimatedForgivenessAmount 0)}}
                    {{safeFormatNumber
                      this.estimatedForgivenessAmount
                      style="currency"
                      currency="USD"
                      maximumFractionDigits=0
                    }}
                  {{/if}}
                </p>
              </div>
              {{#if (gte this.estimatedMonthlySavings 0)}}
                <p class="text-tio-gray-700 ml-23 text-center md:text-xs">
                  {{t
                    "recommendations_estimator.savings"
                    text=(safeFormatNumber
                      this.estimatedMonthlySavings
                      style="currency"
                      currency="USD"
                      maximumFractionDigits=0
                    )
                  }}
                </p>
              {{/if}}
            </:body>
          </Section>
        {{/if}}
        {{#if (screenIs "md")}}
          <Section>
            <:header>
              <div class="flex flex-row-reverse text-sm gap-x-4">
                <TioPdfPrintButton
                  @url={{@recommendation.downloadUrl}}
                  @filename={{@recommendation.downloadFilename}}
                />
                <TioDownloadButton
                  @url={{@recommendation.downloadUrl}}
                  @filename={{@recommendation.downloadFilename}}
                />
              </div>
            </:header>
            <:body>
              <table class="text-sm w-full mb-4 pslf-dashboard-results-table table-fixed">
                <thead>
                  <tr>
                    <td class="border"></td>
                    {{#each this.bestHeaderColumn as |column|}}
                      <th
                        scope="col"
                        class="text-left align-top even:bg-ocean-600 even:text-white border font-semibold p-4"
                      >
                        <p class="mb-2 font-bold text-lg">{{column.title}}</p>

                        <div class="lg:m-4">
                          {{#each column.headers as |header|}}
                            {{#if this.showBestHeaderTitle}}
                              {{! @glint-expect-error: need types }}
                              <p class="mb-1">{{header.title}}</p>
                            {{/if}}
                            <ul class="list-disc list-inside mb-2">
                              {{! @glint-expect-error: need types }}
                              {{#each header.bullets as |bullet|}}
                                {{#if bullet}}
                                  <li>{{bullet}}</li>
                                {{/if}}
                              {{/each}}
                            </ul>
                          {{/each}}
                        </div>
                      </th>
                    {{/each}}
                    {{#each this.currentHeaderColumn as |column|}}
                      <th
                        scope="col"
                        class="text-left align-top even:bg-ocean-600 even:text-white border font-semibold p-4"
                      >
                        <p class="mb-2 font-bold text-lg">{{column.title}}</p>

                        <div class="lg:m-4">
                          {{#each column.headers as |header|}}
                            {{#if this.showCurrentHeaderTitle}}
                              {{! @glint-expect-error: need types }}
                              <p class="mb-1">{{header.title}}</p>
                            {{/if}}
                            <ul class="list-disc list-inside mb-2">
                              {{! @glint-expect-error: need types }}
                              {{#each header.bullets as |bullet|}}
                                {{#if bullet}}
                                  <li>{{bullet}}</li>
                                {{/if}}
                              {{/each}}
                            </ul>
                          {{/each}}
                        </div>
                      </th>
                    {{/each}}
                  </tr>
                </thead>
                <tbody>
                  {{#each this.tableRowData as |row|}}
                    <tr>
                      <td class="text-left align-top text-gray-700 border p-4">
                        {{row.title}}
                        {{#if row.subtitle}}
                          <div class="text-xs font-normal pl-2">{{row.subtitle}}</div>
                        {{/if}}
                      </td>
                      <td class="text-center align-top bg-ocean-50 border p-4">{{row.col1Data}}
                        {{#if row.asterisk}}
                          <span>*</span>
                        {{/if}}
                      </td>
                      <td class="text-center align-top border p-4">{{row.col2Data}}</td>
                    </tr>
                  {{/each}}
                </tbody>
              </table>
            </:body>
          </Section>
          {{#if this.showReducedResultCard}}
            <div class="mb-8 flex justify-center">
              <Button
                @appearance="outlined"
                @intent="primary"
                {{on "click" (transitionTo this.linkToNextSteps)}}
                class="min-w-56"
              >
                {{t "continue"}}
              </Button>
            </div>
          {{/if}}
        {{else}}
          <AppContentCard
            @title={{this.bestPlanColumn.title}}
            @titleClass="uppercase text-ocean-600 text-lg font-semibold"
            class="bg-ocean-50 m-4"
          >
            <div class="ml-4 text-ocean-600 font-semibold">
              {{#each this.bestPlanColumn.headers as |header|}}
                {{! @glint-expect-error: need types }}
                <p class="mb-1">{{header.title}}</p>
                <ul class="list-disc list-inside mb-2">
                  {{! @glint-expect-error: need types }}
                  {{#each header.bullets as |bullet|}}
                    {{#if bullet}}
                      <li>{{bullet}}</li>
                    {{/if}}
                  {{/each}}
                </ul>
              {{/each}}
            </div>
            <Divider class="my-2" />
            <div class="ml-4">
              {{#each this.bestPlanSummaryCard as |data|}}
                <div class="my-2">
                  <h2 class="font-semibold text-gray-700">{{data.title}}</h2>
                  {{#if data.subtitle}}
                    <p class="text-xs font-normal mx-1 text-gray-700">{{data.subtitle}}</p>
                  {{/if}}
                  <div class="flex">
                    <p class="ml-4">{{data.planText}}</p>
                    {{#if data.asterisk}}
                      <span>*</span>
                    {{/if}}
                  </div>
                  {{#if data.footnote}}
                    <p class="text-xs font-normal pl-2">{{data.footnote}}</p>
                  {{/if}}
                </div>
              {{/each}}
            </div>
            <div class="flex justify-end">
              <TioPdfPrintButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="border-none bg-transparent"
              />
              <TioDownloadButton
                @url={{@recommendation.downloadUrl}}
                @filename={{@recommendation.downloadFilename}}
                class="border-none bg-transparent"
              />
            </div>
          </AppContentCard>
          <AppContentCard
            @title={{this.currentPlanColumn.title}}
            @titleClass="uppercase text-ocean-600 text-lg font-semibold"
            class="m-4"
          >
            <div class="ml-4 text-ocean-600 font-semibold">
              {{#each this.currentPlanColumn.headers as |header|}}
                {{! @glint-expect-error: need types }}
                <p class="mb-1">{{header.title}}</p>
                <ul class="list-disc list-inside mb-2">
                  {{! @glint-expect-error: need types }}
                  {{#each header.bullets as |bullet|}}
                    {{#if bullet}}
                      <li>{{bullet}}</li>
                    {{/if}}
                  {{/each}}
                </ul>
              {{/each}}
            </div>
            <Divider class="my-2" />
            <div class="ml-4">
              {{#each this.currentPlanSummaryCard as |data|}}
                <div class="my-2">
                  <h2 class="font-semibold text-gray-700">{{data.title}}</h2>
                  {{#if data.subtitle}}
                    <p class="text-xs font-normal mx-1 text-gray-700">{{data.subtitle}}</p>
                  {{/if}}
                  <p class="ml-4">{{data.planText}}</p>
                </div>
              {{/each}}
            </div>
          </AppContentCard>
          {{#if this.showReducedResultCard}}
            <div class="flex justify-center">
              <Button
                @appearance="outlined"
                @intent="primary"
                {{on "click" (transitionTo this.linkToNextSteps)}}
                class="min-w-56"
              >
                {{t "continue"}}
              </Button>
            </div>
          {{/if}}
        {{/if}}
      {{/if}}
    </VStack>
    {{#if this.showEstimatorSummaryDialog}}
      <ForgivenessEstimatorEstimatorSummaryDialog
        @programType={{@programType}}
        {{! @glint-expect-error: need to get definitive answer on mut }}
        @didCancel={{fn (mut this.showEstimatorSummaryDialog) false}}
        @recommendation={{@recommendation}}
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
