import { template as template_ac8446fcfdca4ae29d52d231a2c0a169 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import TioLoadingSpinner from 'tio-common/components/tio/loading-spinner';
export default RouteTemplate(template_ac8446fcfdca4ae29d52d231a2c0a169(`
    <div class="flex h-screen w-screen items-center justify-center">
      <TioLoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
