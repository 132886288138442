import { template as template_c2ee82fee0aa435c912a4ae388779b7b } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
export default class TuitionAssistanceDashboardProgramHeader extends Component {
    @service
    router: RouterService;
    @service
    sessionContext: SessionContextService;
    get isDependentProgramsEnabled() {
        return (this.sessionContext.currentEmployee?.company.companySetting.tuitionAssistance?.isDependentProgramsEnabled || false);
    }
    get showDependentProgramsButton(): boolean {
        return (this.router.currentRouteName === 'authenticated.tuition-assistance.dashboard' && this.isDependentProgramsEnabled);
    }
    static{
        template_c2ee82fee0aa435c912a4ae388779b7b(`
    <div class="flex justify-between items-center">
      <h2 class="text-lg font-semibold">
        {{t "common.tuition_assistance.dashboard.program_header"}}
      </h2>
      {{#if this.showDependentProgramsButton}}
        <div>
          <Button
            @intent="primary"
            @appearance="outlined"
            {{on "click" (transitionTo "authenticated.tuition-assistance.dependent-programs")}}
            data-test-dependent-programs-button
          >
            <span class="flex items-end">{{svgJar "people-outline-24" class="mr-2"}}
              {{t "common.tuition_assistance.dependent_programs_button"}}
            </span>
          </Button>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
