import { template as template_61cec0cc04c24fca80c768179b6ab387 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import type AccountModel from 'tio-common/models/account';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import type RouterService from 'tio-employee/services/router';
import SyfAccountLinked from 'tio-employee/components/syf/account-linked';
interface SyfLinkingConfirmationRouteSignature {
    Args: {
        model: AccountModel;
    };
}
class SyfLinkingConfirmationRouteComponent extends Component<SyfLinkingConfirmationRouteSignature> {
    @service
    router: RouterService;
    @action
    didConfirm() {
        this.router.transitionTo('authenticated.syf.account-confirmation');
    }
    static{
        template_61cec0cc04c24fca80c768179b6ab387(`<SyfAccountLinked @account={{@model}} @onConfirmation={{this.didConfirm}} />`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfLinkingConfirmationRouteComponent);
