import { template as template_da04dc8b249745b2b9e3061f7f7c5ce7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router-service';
import { htmlSafe } from '@ember/template';
import { hash } from '@ember/helper';
import DocumentArrowUp from 'ember-static-heroicons/components/outline-24/document-arrow-up';
import ArrowDownTray from 'ember-static-heroicons/components/outline-24/arrow-down-tray';
import Envelope from 'ember-static-heroicons/components/outline-24/envelope';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import Badge from 'tio-ui/components/badge';
import ActionableTranslation from 'tio-ui/components/actionable-translation';
import type { Intent } from 'tio-ui/utilities';
import { and, or } from 'tio-ui/utilities';
import { getStatus, getDisputeNote } from 'tio-common/helpers/observability';
import ExtractionSummary from 'tio-employee/components/observability/extraction-summary';
import { VStack, HStack, Header, Section } from 'tio-ui/components/layout';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
interface RouteSignature {
    Args: {
        model: ObservableDocumentModel;
    };
}
const getStatusIntent = (status: string): Intent | undefined =>{
    switch(status){
        case 'PROCESSING':
            return 'primary';
        case 'INVALID':
        case 'FAILED':
        case 'NOT_APPLIED':
            return 'error';
        case 'PROCESSED':
        case 'APPLIED':
            return 'success';
        case 'IN_DISPUTE':
        case 'PARTIALLY_APPLIED':
            return 'warning';
    }
};
const getSubmittedIcon = (via: string)=>{
    if (via === 'EMAIL') {
        return Envelope;
    }
    return DocumentArrowUp;
};
const formatDisputeNote = (note?: string): string | undefined =>{
    if (note) {
        return `"${note}"`;
    }
};
class ObservabilityDocumentsShow extends Component<RouteSignature> {
    @service
    intl: IntlService;
    @service
    router: RouterService;
    get documentCreated() {
        const observableDocument = this.args.model;
        const createdAtDate = new Date(observableDocument.createdAt);
        const date = createdAtDate.toLocaleDateString();
        const time = createdAtDate.toLocaleTimeString();
        const via = this.intl.t(`observability.${observableDocument.via}`);
        return {
            via,
            date,
            time
        };
    }
    get amendRoute(): string {
        return this.router.urlFor('authenticated.observability.dispute', this.args.model.id);
    }
    get documentSuccessful() {
        return this.args.model?.extractionState === 'ExtractionState.PROCESSED';
    }
    get documentUnsuccessful() {
        return [
            'ExtractionState.FAILED',
            'ExtractionState.INVALID'
        ].includes(this.args.model?.extractionState);
    }
    get documentInReview() {
        return this.args.model?.subsistenceState === 'SubsistenceState.IN_DISPUTE';
    }
    get documentPartiallyApplied() {
        return this.args.model?.reflectionState === 'ReflectionState.PARTIALLY_APPLIED';
    }
    get documentDisputable() {
        const document = this.args.model;
        const providerTypeDisputable = [
            'ObservableProvider.ACCOUNT_STATEMENT',
            'ObservableProvider.NSLDS'
        ].includes(document?.provider);
        return providerTypeDisputable && document?.subsistenceState === 'SubsistenceState.IMPLICIT';
    }
    get documentDisputedOrDisputable() {
        return [
            'SubsistenceState.IN_DISPUTE',
            'SubsistenceState.IMPLICIT'
        ].includes(this.args.model?.subsistenceState);
    }
    static{
        template_da04dc8b249745b2b9e3061f7f7c5ce7(`
    <VStack>
      <Header>
        <TioPageBreadcrumbs as |b|>
          <b.crumb
            @route="authenticated.account-activity.accounts"
            @label={{t "accounts.my_accounts"}}
          />
          <b.crumb @label={{t "observability.document.summary.header"}} />
        </TioPageBreadcrumbs>
      </Header>
      <Section>
        <:header>
          <HStack class="justify-between">
            <hgroup>
              <h1 class="inline">{{@model.observableAsset.filename}}</h1>
              <Badge @intent={{getStatusIntent (getStatus @model)}} class="ml-4">
                {{t "observable_document.status" state=(getStatus @model)}}
              </Badge>
              <p class="italic flex items-center text-small font-normal mt-2">
                {{#let (getSubmittedIcon @model.via) as |SubmittedIcon|}}
                  <SubmittedIcon class="w-6 mr-2" />
                {{/let}}
                {{t
                  "observability.dispute.document.uploaded"
                  via=this.documentCreated.via
                  date=this.documentCreated.date
                  time=this.documentCreated.time
                }}
              </p>
            </hgroup>
            <a
              href={{@model.observableAsset.signedUrl}}
              target="_blank"
              rel="noopener noreferrer"
              class="tio-btn tio-btn-default tio-btn-outlined ml-auto"
            >
              <ArrowDownTray class="inline w-4 mr-1" />
              {{t "download"}}
            </a>
          </HStack>
        </:header>
        <:body>
          {{#if this.documentSuccessful}}
            <ExtractionSummary @document={{@model}} class="w-full md:w-2/3" />
            <footer>
              {{#if (or this.documentDisputedOrDisputable this.documentPartiallyApplied)}}
                <p class="my-8">
                  {{#if (and this.documentInReview (getDisputeNote @model))}}
                    {{formatDisputeNote (getDisputeNote @model)}}
                  {{else if this.documentDisputable}}
                    {{! TODO: genericize this instead of stealing it from the confirmation page }}
                    <ActionableTranslation
                      @t="observability.confirmation.amend"
                      @links={{hash clickHere=(hash t="click_here_lowercase" href=this.amendRoute)}}
                    />
                  {{else if this.documentPartiallyApplied}}
                    <p class="my-8">
                      {{t "observability.document.summary.partially_applied"}}
                    </p>
                  {{/if}}
                </p>
              {{/if}}
            </footer>
          {{else}}
            <p class="text-red-700 my-8">
              {{t "observability.documents.show.unsuccessful"}}
            </p>
            <p class="my-4">
              {{t "observability.uploads.support.prompt"}}
              <span class="block text-sm my-2">
                {{htmlSafe
                  (t
                    "observability.uploads.support.email"
                    email="support@tuition.io"
                    linkClass="tio-link"
                  )
                }}
              </span>
              <span class="block text-sm my-2">
                {{t "observability.uploads.support.phone"}}
              </span>
            </p>
          {{/if}}
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(ObservabilityDocumentsShow);
