import { template as template_5d02a7065e154f55a5d707cf4f240cb6 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { Header, VStack } from 'tio-ui/components/layout';
interface StrategyFinderDashboardRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<StrategyFinderDashboardRouteSignature>(template_5d02a7065e154f55a5d707cf4f240cb6(`
    <VStack>
      <Header>{{t "common.strategy_finder.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
