import { template as template_25e9c5988082439ea6acd77110f6ef9c } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
import { Header, VStack } from 'tio-ui/components/layout';
interface SyfRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfRouteSignature>(template_25e9c5988082439ea6acd77110f6ef9c(`
    <VStack>
      <Header>{{t "syf.default"}}</Header>
      {{outlet}}
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
