import { template as template_65e7ab09755044468d4bd07d3a425084 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
interface SyfPaymentsRouteSignature {
    Blocks: {
        default: [];
    };
}
export default RouteTemplate<SyfPaymentsRouteSignature>(template_65e7ab09755044468d4bd07d3a425084(`{{outlet}}`, {
    eval () {
        return eval(arguments[0]);
    }
}));
