import { template as template_dd75207694b64b05849c94e9c1839236 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
import { t } from 'ember-intl';
export default RouteTemplate(template_dd75207694b64b05849c94e9c1839236(`
    {{pageTitle (t "recommendations_estimator.forgiveness_estimator")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
