import { template as template_61ad50dd469841dbbf5ef85768bad7d5 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';
export default RouteTemplate(template_61ad50dd469841dbbf5ef85768bad7d5(`
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
