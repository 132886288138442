import { template as template_596b2b1b50b34cbeb138d7dfc2494b7c } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import FormSelect from '@frontile/forms-legacy/components/form-select';
import PslfAdminCompanyFormsTable from 'tio-employee/components/pslf/admin/company-forms-table';
import RouteTemplate from 'ember-route-template';
import { SearchInput } from 'tio-ui/components/forms';
import type { IntlService } from 'ember-intl';
import type { PslfForm } from 'tio-common/models/search';
import type AdminPslfFormsController from 'tio-employee/controllers/authenticated/admin/pslf/forms';
import type RouterService from '@ember/routing/router-service';
import type SearchModel from 'tio-common/models/search';
import type SessionContextService from 'tio-employee/services/session-context';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import type Owner from '@ember/owner';
export interface AdminPslfFormsRouteSignature {
    Args: {
        controller: AdminPslfFormsController;
        model: SearchModel<PslfForm>;
    };
}
class AdminPslfFormsRoute extends Component<AdminPslfFormsRouteSignature> {
    @service
    sessionContext: SessionContextService;
    @service
    intl: IntlService;
    @service
    router: RouterService;
    @tracked
    selectedGroupFilter: {
        text: string;
        value: string;
    } | null = null;
    @tracked
    selectedStatusFilter: {
        text: string;
        value: string;
    } | null = null;
    @tracked
    selectedFormTypeFilter = {
        text: 'Current Employer',
        value: 'currentEmployer'
    };
    formTypes = [
        'currentEmployer',
        'priorEmployer'
    ];
    statusOptions = [
        'borrower_signed',
        'employer_signed',
        'rejected',
        'awaiting_submit',
        'submit_pending',
        'submit_errored',
        'submitted',
        'cancelled',
        're_submitted',
        're_submit_pending',
        're_submit_errored',
        'expired'
    ];
    statusFilterItems = this.statusOptions.map((status)=>{
        if (status === 'borrower_signed') {
            return {
                text: this.intl.t(`common.pslf.status.needs_review`),
                value: status
            };
        }
        return {
            text: this.intl.t(`common.pslf.status.${status}`),
            value: status
        };
    });
    groupFilterItems = this.groups.map((group)=>{
        return {
            text: group.employer.legalName,
            value: group.id
        };
    });
    formTypeFilterItems = this.formTypes.map((type)=>{
        const formType = type === 'currentEmployer' ? 'current_employer' : 'prior_employer';
        return {
            text: this.intl.t(`pslf.forms.${formType}`),
            value: type
        };
    });
    constructor(owner: Owner, args: AdminPslfFormsRouteSignature['Args']){
        super(owner, args);
        this.selectedStatusFilter = this.statusFilterItems.find((statusFilterItem)=>statusFilterItem.value === args.controller.status) || null;
        this.selectedGroupFilter = this.groupFilterItems.find((groupFilterItem)=>groupFilterItem.value === args.controller.group) || null;
        this.selectedFormTypeFilter = this.formTypeFilterItems.find((formTypeFilterItem)=>formTypeFilterItem.value === args.controller.formType) || {
            text: 'Current Employer',
            value: 'currentEmployer'
        };
    }
    get groups() {
        return this.company?.companySetting.modules.pslf?.groups || [];
    }
    get company() {
        return this.sessionContext.currentRole?.company;
    }
    get isGroupApprover() {
        return this.sessionContext.isPslfGroupApprover;
    }
    @action
    setSelectedGroupFilter(groupItem: {
        text: string;
        value: string;
    }) {
        this.args.controller.group = groupItem?.value || '';
        this.selectedGroupFilter = groupItem;
    }
    @action
    setSelectedStatusFilter(statusItem: {
        text: string;
        value: string;
    }) {
        this.args.controller.status = statusItem?.value || '';
        this.selectedStatusFilter = statusItem;
    }
    @action
    setSelectedFormTypeFilter(formType: {
        text: string;
        value: string;
    }) {
        this.args.controller.formType = formType?.value || '';
        this.selectedFormTypeFilter = formType;
    }
    @action
    didSearch(string: string) {
        this.args.controller.search = string;
    }
    static{
        template_596b2b1b50b34cbeb138d7dfc2494b7c(`
    <VStack>
      <Header>{{t "pslf.forms.index_page_title"}}</Header>
      <Section>
        <:body>
          <div class="grid grid-rows-1 grid-flow-col gap-4">
            <div>
              <label class="font-semibold text-gray-600" aria-hidden={{true}}>
                {{t "pslf.forms.search_forms"}}
              </label>
              <SearchInput
                @placeholder="Search (Name, Email)"
                @searchString={{@controller.search}}
                @onInput={{this.didSearch}}
                @onSubmit={{this.didSearch}}
                {{! @glint-expect-error: power select bug}}
                @allowClear={{true}}
              />
            </div>
            <div>
              <FormSelect
                data-legacy-input
                @label="By group"
                @options={{this.groupFilterItems}}
                @selected={{this.selectedGroupFilter}}
                @onChange={{this.setSelectedGroupFilter}}
                {{! @glint-expect-error: power select bug}}
                @allowClear={{true}}
                as |item|
              >
                {{! @glint-expect-error: power select bug}}
                {{item.text}}
              </FormSelect>
            </div>
            <div>
              <FormSelect
                data-legacy-input
                @label="By status"
                @options={{this.statusFilterItems}}
                @selected={{this.selectedStatusFilter}}
                @onChange={{this.setSelectedStatusFilter}}
                {{! @glint-expect-error: power select bug}}
                @allowClear={{true}}
                as |item|
              >
                {{! @glint-expect-error: issue with frontile types}}
                {{item.text}}
              </FormSelect>
            </div>
            {{#unless this.isGroupApprover}}
              <div>
                <FormSelect
                  data-legacy-input
                  @label="By form type"
                  @options={{this.formTypeFilterItems}}
                  @selected={{this.selectedFormTypeFilter}}
                  @onChange={{this.setSelectedFormTypeFilter}}
                  {{! @glint-expect-error: power select bug}}
                  @allowClear={{true}}
                  as |item|
                >
                  {{! @glint-expect-error: issue with frontile types}}
                  {{item.text}}
                </FormSelect>
              </div>
            {{/unless}}
          </div>
        </:body>
      </Section>
      <Section>
        <:body>
          <PslfAdminCompanyFormsTable @model={{@model}} @controller={{@controller}} />
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(AdminPslfFormsRoute);
