import { template as template_dfe111b135e942ba94a318010e16a617 } from "@ember/template-compiler";
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import RouteTemplate from 'ember-route-template';
import type AccountModel from 'tio-common/models/account';
import type MatchParticipantModel from 'tio-common/models/match-participant';
import type MatchPlanModel from 'tio-common/models/match-plan';
import { MATCH_PAYMENT_QUALIFICATION_STATE } from 'tio-common/models/match-payment';
import type MatchPaymentModel from 'tio-common/models/match-payment';
import type AccountTransactionModel from 'tio-common/models/account-transaction';
import { ACCOUNT_TRANSACTION_VERIFICATION_STATE } from 'tio-common/models/account-transaction';
import type MatchPaymentService from 'tio-employee/services/match-payment';
import SyfDashboard from 'tio-employee/components/syf/dashboard';
interface SyfDashboardRouteSignature {
    Args: {
        model: {
            accounts: Array<AccountModel>;
            matchParticipant: MatchParticipantModel;
            matchPlan: MatchPlanModel;
            matchPayments: Array<MatchPaymentModel>;
            accountTransactions: Array<AccountTransactionModel>;
        };
    };
}
class SyfDashboardRouteComponent extends Component<SyfDashboardRouteSignature> {
    @service
    matchPayment: MatchPaymentService;
    @tracked
    focusedAccount: AccountModel | null = null;
    @tracked
    showAccountVerifyAlert: boolean = false;
    @tracked
    showAccountUnverifyAlert: boolean = false;
    @tracked
    accountBorrowerConfirmed: boolean = false;
    @tracked
    accountVerificationError = null;
    @action
    toggleAccountBorrowerConfirmed(): void {
        this.accountBorrowerConfirmed = !this.accountBorrowerConfirmed;
    }
    @action
    toggleAccountIsVerified(account: AccountModel) {
        this.focusedAccount = account;
        if (!account.isVerified) {
            this.showAccountVerifyAlert = true;
        } else {
            this.showAccountUnverifyAlert = true;
        }
    }
    // this goes for either possible isVerified attr boolean val
    @action
    async confirmAccountIsVerified(account: AccountModel) {
        account.isVerified = !account.isVerified; // toggle the isVerified attribute
        try {
            await account.save(); // save the record
            this.closeAccountIsVerifiedAlert();
        } catch (e) {
            account.isVerified = !account.isVerified; // if this didn't work, switch it back
            this.accountVerificationError = e; // set the error to ivar
        }
    }
    @action
    async certifyPayments(payments: Array<MatchPaymentModel>) {
        await this.matchPayment.certifyMatchPayments(payments);
    }
    @action
    closeAccountIsVerifiedAlert(): void {
        this.showAccountVerifyAlert = false;
        this.showAccountUnverifyAlert = false;
        this.accountBorrowerConfirmed = false;
        this.accountVerificationError = null;
        this.focusedAccount = null;
    }
    // match payment getters
    get certifiedPayments() {
        return this.args.model.matchPayments.filter((payment)=>payment.certified);
    }
    get qualifiedPayments() {
        return this.args.model.matchPayments.filter((payment: MatchPaymentModel)=>payment.qualificationState === MATCH_PAYMENT_QUALIFICATION_STATE.QUALIFIED).filter((payment)=>!payment.certified);
    }
    get unqualifiedPayments() {
        return this.args.model.matchPayments.filter((payment: MatchPaymentModel)=>payment.qualificationState === MATCH_PAYMENT_QUALIFICATION_STATE.UNQUALIFIED);
    }
    // account transaction getters
    get eligiblePayments() {
        const { accountTransactions, matchParticipant } = this.args.model;
        return accountTransactions.filter((txn)=>{
            const matchPayment = txn.matchPayments.find((txnPayment: MatchPaymentModel)=>txnPayment.matchParticipant === matchParticipant);
            return matchPayment?.qualificationState === MATCH_PAYMENT_QUALIFICATION_STATE.QUALIFIED;
        });
    }
    get unverifiedPayments() {
        return this.args.model.accountTransactions.filter((txn)=>{
            return [
                ACCOUNT_TRANSACTION_VERIFICATION_STATE.PENDING_VERIFICATION,
                ACCOUNT_TRANSACTION_VERIFICATION_STATE.REJECTED
            ].includes(txn.verificationState);
        });
    }
    get ineligiblePayments() {
        const { accountTransactions, matchParticipant } = this.args.model;
        return accountTransactions.filter((txn)=>{
            const matchPayment = txn.matchPayments.find((txnPayment: MatchPaymentModel)=>txnPayment.matchParticipant === matchParticipant);
            return matchPayment?.qualificationState === MATCH_PAYMENT_QUALIFICATION_STATE.UNQUALIFIED;
        });
    }
    // account txns can have multiple match payments, but not multiple match payments with the same
    // match participant; this view wants to display qualification states 1 match payment related to
    // each of its account transactions, and in order to ensure that it uses the appropriate match
    // payment for each account transaction the view needs to find the match payment with the same
    // match participant as the view's model - this is something of an edge case since in all
    // practical cases so far an account transaction's match payments relation will be a one-element
    // array proxy, but it's safer to find the element by its match participant rather than getting
    // the first element with brackets
    getPaymentQualificationState = (accountTransaction: AccountTransactionModel): string =>{
        // get the model match participant closed over in this context (arrow function)
        const { matchParticipant } = this.args.model;
        const matchPayment = accountTransaction.matchPayments.find((txnPayment: MatchPaymentModel)=>txnPayment.matchParticipant === matchParticipant);
        switch(matchPayment?.qualificationState){
            case MATCH_PAYMENT_QUALIFICATION_STATE.QUALIFIED:
                return 'syf.dashboard.payments.qualified.status.certified';
            default:
                return '';
        }
    };
    getPaymentCertificationState = (accountTransaction: AccountTransactionModel): string =>{
        const { matchParticipant } = this.args.model;
        const matchPayment = accountTransaction.matchPayments.find((txnPayment: MatchPaymentModel)=>txnPayment.matchParticipant === matchParticipant);
        return matchPayment?.certified ? 'syf.dashboard.payments.qualified.status.certified' : 'syf.dashboard.payments.qualified.status.pending';
    };
    static{
        template_dfe111b135e942ba94a318010e16a617(`
    <SyfDashboard
      {{! store data }}
      @accounts={{@model.accounts}}
      @matchParticipant={{@model.matchParticipant}}
      @matchPlan={{@model.matchPlan}}
      @qualifiedPayments={{this.qualifiedPayments}}
      @unqualifiedPayments={{this.unqualifiedPayments}}
      @certifiedPayments={{this.certifiedPayments}}
      @eligiblePayments={{this.eligiblePayments}}
      @ineligiblePayments={{this.ineligiblePayments}}
      @unverifiedPayments={{this.unverifiedPayments}}
      @getPaymentQualificationState={{this.getPaymentQualificationState}}
      @getPaymentCertificationState={{this.getPaymentCertificationState}}
      @onCertifyPayments={{this.certifyPayments}}
      {{! component state and callbacks }}
      @focusedAccount={{this.focusedAccount}}
      @showAccountVerifyAlert={{this.showAccountVerifyAlert}}
      @showAccountUnverifyAlert={{this.showAccountUnverifyAlert}}
      @onToggleAccountVerified={{this.toggleAccountIsVerified}}
      @onConfirmAccountIsVerified={{this.confirmAccountIsVerified}}
      @onCancelAccountIsVerified={{this.closeAccountIsVerifiedAlert}}
      @accountBorrowerConfirmed={{this.accountBorrowerConfirmed}}
      @onToggleAccountBorrowerConfirmed={{this.toggleAccountBorrowerConfirmed}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(SyfDashboardRouteComponent);
