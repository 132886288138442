import { template as template_358b3433e91e455395be7e33bcb99872 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import CompanySettingModel from 'tio-common/models/company-setting';
import Component from '@glimmer/component';
import emailSchema from 'tio-common/validation-schema/models/email';
import ENV from 'tio-employee/config/environment';
import formatSubdomainRedirectUrl from 'tio-common/utils/format-subdomain-redirect-url';
import FormInput from '@frontile/forms-legacy/components/form-input';
import LoginHere from '../login-here';
import type { IntlService } from 'ember-intl';
import type { NotificationsService } from 'tio-ui/components/notifications';
import type PreRegisterService from '../../../services/pre-register.ts';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type Owner from '@ember/owner';
import { Button } from 'tio-ui/components/buttons';
interface AuthRegisterEmployeeRegisterFormComponentSignature {
    Args: {
        settings: CompanySettingModel;
        email: string;
    };
}
export default class AuthRegisterEmployeeRegisterFormComponent extends Component<AuthRegisterEmployeeRegisterFormComponentSignature> {
    @service
    store: StoreService;
    @service
    preRegister: PreRegisterService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    @service
    notifications: NotificationsService;
    @tracked
    email: string;
    @tracked
    isLoading = false;
    constructor(owner: Owner, args: AuthRegisterEmployeeRegisterFormComponentSignature['Args']){
        super(owner, args);
        this.email = args.email;
    }
    get company() {
        return this.preRegister.sparseCompany;
    }
    get validationErrors() {
        try {
            // @ts-expect-error: same as email-field.gts
            emailSchema.fields.email?.validateSync?.(this.email);
        } catch (err) {
            return err.errors || [];
        }
        return [];
    }
    get companyName() {
        return this.args.settings.companyName || '';
    }
    get registerPageCopy() {
        return this.args.settings.registerPageCopy;
    }
    registerTask = dropTask(async ()=>{
        const { host } = this.store.adapterFor('application');
        const response = await fetch(`${host}/preregister`, {
            method: 'POST',
            body: JSON.stringify({
                email: this.email,
                company_id: this.company?.id || null
            }),
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': ENV.apiKey
            }
        });
        if (response.ok) {
            const res = await response.json();
            if (res.sub_domain) {
                const redirectUrl = formatSubdomainRedirectUrl({
                    subDomain: res.sub_domain,
                    domain: this.preRegister.parseHostname(window.location.hostname).domain,
                    email: this.email,
                    route: 'register'
                });
                window.location.href = redirectUrl;
            } else {
                this.router.transitionTo('register.confirmation');
            }
        } else {
            const res = await response.json();
            this.notifications.add(res.error || 'An Error Occurred', {
                appearance: 'error'
            });
        }
        this.isLoading = false;
    });
    get isDisabled() {
        return !this.email || this.validationErrors.length;
    }
    @action
    submit(e?: Event) {
        e?.preventDefault();
        this.isLoading = true;
        this.registerTask.perform();
    }
    static{
        template_358b3433e91e455395be7e33bcb99872(`
    <form {{on "submit" this.submit}}>
      {{#if this.registerPageCopy}}
        <h1 class="text-xl my-4">
          {{this.registerPageCopy}}
        </h1>
      {{else}}
        <div class="flex flex-col items-center">
          <h1 class="text-2xl my-2">
            {{t "login.hello_and_welcome"}}
          </h1>
          <h2 class="text-lg mb-6 text-center">{{t "login.what_email"}}</h2>
        </div>
      {{/if}}
      <div class="mb-10">
        <FormInput
          data-legacy-input
          @label={{t "login.email"}}
          @value={{this.email}}
          @hint={{t "login.email_redirect"}}
          @onInput={{fn (mut this.email)}}
          @errors={{this.validationErrors}}
          @type="email"
        />
      </div>

      <div class="flex flex-col items-center">
        <Button
          @intent="primary"
          {{on "click" this.submit}}
          @isRunning={{this.isLoading}}
          disabled={{this.isDisabled}}
          class="w-36 mb-10"
        >
          {{t "submit"}}
        </Button>
      </div>
      <LoginHere />
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
