import { template as template_f0d40cdcc5a74637b44b7f8c0475089c } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import SelectParticipationStatus from 'tio-common/components/select/participation-status';
import SelectPlans from 'tio-common/components/select/plans';
import Table from 'tio-common/components/table/index';
import TablePaginator from 'tio-common/components/table/paginator';
import { SearchInput } from 'tio-ui/components/forms';
import type EmployeeModel from 'tio-common/models/employee';
import type EmployeesIndexController from 'tio-employee/controllers/authenticated/admin/employees/index';
import type PartnerService from 'tio-employee/services/partner';
import type PlanModel from 'tio-common/models/plan';
import type RouterService from '@ember/routing/router';
import type StoreService from 'tio-common/services/store';
import TioPageBreadcrumbs from 'tio-common/components/tio/page/breadcrumbs';
export interface S {
    Args: {
        controller: EmployeesIndexController;
        model: EmployeeModel[];
    };
}
class EmployeeIndexRoute extends Component<S> {
    @service
    partner: PartnerService;
    @service
    store: StoreService;
    @service
    router: RouterService;
    @tracked
    selectedPlanFilter?: PlanModel;
    @tracked
    selectedStatusFilter = '';
    @tracked
    checklist: string[] = [];
    @tracked
    showCheckboxes = false;
    @tracked
    newSelectedPlan = '';
    @tracked
    changePlans = false;
    @tracked
    isGrid = false;
    @tracked
    setSelectedCompanyFilter = '';
    get company() {
        return this.partner.company;
    }
    get plans() {
        return this.partner.company?.plans || [];
    }
    @action
    didSearch(string: string) {
        this.args.controller.search = string;
    }
    @action
    toggleGrid() {
        this.isGrid = !this.isGrid;
    }
    @action
    createEmployeeTransition() {
        this.router.transitionTo('authenticated.admin.employees.add');
    }
    @action
    addToChecklist(id: string) {
        if (this.checklist.includes(id)) {
            // @ts-expect-error: This is a bug
            this.checklist.pop(id);
        } else {
            this.checklist.push(id);
        }
    }
    @action
    clearChanges() {
        this.changePlans = false;
        this.newSelectedPlan = '';
        this.checklist = [];
        this.showCheckboxes = false;
    }
    @action
    toggleChangePlans() {
        this.changePlans = !this.changePlans;
        this.showCheckboxes = !this.showCheckboxes;
    }
    @action
    setSelectedPlanFilter(model: PlanModel) {
        this.selectedPlanFilter = model;
        this.args.controller.plan = model?.id || '';
    }
    @action
    setSelectedStatusFilter(participationStatus: string) {
        this.args.controller.participationStatus = participationStatus;
    }
    @action
    onSort(sort: string) {
        this.args.controller.sort = sort;
    }
    @action
    previousPage() {
        this.args.controller.page = Math.max(1, this.args.controller.page - 1);
    }
    @action
    nextPage() {
        ++this.args.controller.page;
    }
    get totalPages() {
        const { model, controller } = this.args;
        // @ts-expect-error: ed needs types
        const meta = model?.meta;
        if (meta?.page.total) {
            return Math.ceil(meta.page.total / controller.limit);
        }
        return 0;
    }
    static{
        template_f0d40cdcc5a74637b44b7f8c0475089c(`
    {{pageTitle (t "partner.employees")}}

    <VStack>
      <div class="flex justify-between">
        <TioPageBreadcrumbs as |b|>
          <b.crumb @label="Employees" />
          <b.crumb @label="Admin" />
        </TioPageBreadcrumbs>
        <Button
          @intent="primary"
          @appearance="outlined"
          {{on "click" this.createEmployeeTransition}}
        >
          {{t "partner.employee.create_employee"}}
        </Button>
      </div>
      <Section class="sticky top-0">
        <:body>
          <div class="w-1/4 inline-block align-bottom">
            <label class="text-xs" aria-hidden={{true}}>
              {{t "partner.employee.search"}}
            </label>
            <SearchInput
              @placeholder="Search (Name, Payroll ID, Email)"
              @searchString={{@controller.search}}
              @onInput={{this.didSearch}}
              @onSubmit={{this.didSearch}}
              class="max-w-screen-sm"
            />
          </div>
          <div class="w-1/4 ml-2 inline-block align-bottom">
            <SelectPlans
              @label="Filter by plan"
              @plans={{this.plans}}
              @companyId={{this.company.id}}
              @selected={{this.selectedPlanFilter}}
              @onChange={{this.setSelectedPlanFilter}}
            />
          </div>
          <div class="w-1/4 ml-2 inline-block align-bottom">
            <SelectParticipationStatus
              @placeholder={{@controller.participationStatus}}
              @label="Filter by status"
              {{! @glint-expect-error: fairly certain this is a bug }}
              @participationStatus={{@model.status}}
              @selected={{@controller.participationStatus}}
              @onChange={{this.setSelectedStatusFilter}}
              @companyId={{this.company.id}}
            />
          </div>
        </:body>
      </Section>

      <Section class="overflow-x-auto">
        <:body>
          {{! @glint-expect-error: return once ED has better types }}
          <Table @isLoading={{@model.isLoading}}>
            <:thead as |options|>
              <options.tr>
                <options.th
                  @sortField="payroll-id"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.payroll_id"}}
                </options.th>
                <options.th
                  @sortField="first-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.first_name"}}
                </options.th>
                <options.th
                  @sortField="last-name"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.last_name"}}
                </options.th>
                <options.th
                  @sortField="email"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.email"}}
                </options.th>
                <options.th
                  @sortField="participation-status"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.status"}}
                </options.th>
                <options.th
                  @sortField="plan.company-key"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.plan"}}
                </options.th>
                <options.th
                  @sortField="eligibility-start-date"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.eligibility_start_date"}}
                </options.th>
                {{! using incorrect date }}
                <options.th
                  @sortField="created-at"
                  @activeSortField={{@controller.sort}}
                  @onSort={{this.onSort}}
                >
                  {{t "partner.employee.activation_date"}}
                </options.th>
                <options.th>{{t "partner.employee.accounts"}}</options.th>
                <options.th>{{t "partner.employee.loans"}}</options.th>
              </options.tr>
            </:thead>
            <:tbody as |options|>
              {{#each @model as |employee|}}
                <options.tr>
                  <options.td>
                    <LinkTo
                      class="text-primary-500 underline"
                      @route="authenticated.admin.employees.show"
                      @model={{employee.id}}
                    >
                      {{employee.payrollId}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.firstName}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.lastName}}
                    </LinkTo>
                  </options.td>
                  <options.td>{{employee.email}}</options.td>
                  <options.td>{{employee.participationStatus}}</options.td>
                  <options.td>{{employee.plan.name}}</options.td>
                  <options.td>
                    {{safeFormatDate
                      employee.eligibilityStartDate
                      month="long"
                      day="2-digit"
                      year="numeric"
                    }}
                  </options.td>
                  {{! this is the wrong date }}
                  <options.td>
                    {{safeFormatDate employee.createdAt month="long" day="2-digit" year="numeric"}}
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.statistics.user.total_accounts}}
                    </LinkTo>
                  </options.td>
                  <options.td>
                    <LinkTo @route="authenticated.admin.employees.show" @model={{employee.id}}>
                      {{employee.statistics.user.total_loans}}
                    </LinkTo>
                  </options.td>
                </options.tr>
              {{/each}}
            </:tbody>
            <:tfoot>
              <tr>
                <td class="text-right" colspan="9">
                  <TablePaginator
                    @page={{@controller.page}}
                    @totalPages={{this.totalPages}}
                    @nextPage={{this.nextPage}}
                    @previousPage={{this.previousPage}}
                    @limit={{@controller.limit}}
                    {{! @glint-expect-error: ed needs types }}
                    @total={{@model.meta.page.total}}
                  />
                </td>
              </tr>
            </:tfoot>
          </Table>
        </:body>
      </Section>
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(EmployeeIndexRoute);
