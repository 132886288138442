import { template as template_44d667399ada469f9e1b88751a7764b6 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { eq } from 'tio-ui/utilities';
import { fn } from '@ember/helper';
import { Footer, Modal } from 'tio-ui/components/modal';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import AccountsLinkedLoansSummary from 'tio-employee/components/accounts/linked-loans-summary';
import Component from '@glimmer/component';
import ForgivenessEstimatorForgivenessEstimatorForm from 'tio-employee/components/forgiveness-estimator/forgiveness-estimator-form';
import StrategyFinderStrategyFinderForm from 'tio-common/components/strategy-finder/strategy-finder-form';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import type { LoanSummary } from 'tio-employee/types/loan-summary';
import type AccountModel from 'tio-common/models/account';
import type IntlService from 'ember-intl/services/intl';
import type PersonModel from 'tio-common/models/person';
import type RecommendationModel from 'tio-common/models/recommendation';
import type RouterService from '@ember/routing/router-service';
import type SessionContextService from 'tio-employee/services/session-context';
import type StoreService from 'tio-common/services/store';
import type UserModel from 'tio-common/models/user';
import { Section, VStack } from 'tio-ui/components/layout';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
export interface MonthlyPaymentForgivenessCardSignature {
    Args: {
        accounts: AccountModel[];
        loanSummary: LoanSummary;
        person: PersonModel;
        programType: string;
        user: UserModel;
        isCanceling: boolean;
    };
    Element: HTMLDivElement;
}
export default class MonthlyPaymentForgivenessCardComponent extends Component<MonthlyPaymentForgivenessCardSignature> {
    @service
    store: StoreService;
    @service
    sessionContext: SessionContextService;
    @service
    router: RouterService;
    @service
    intl: IntlService;
    @tracked
    showEstimator = false;
    @tracked
    showMissingInfoDialog = false;
    @tracked
    showEstimatorBlockDialog = false;
    get continueButtonLabel() {
        if (this.args.programType === 'sf') {
            return this.intl.t('strategy_finder.continue_to_sf');
        } else {
            return this.intl.t('forgiveness_estimator.continue_to_estimator');
        }
    }
    @action
    checkLoansAndGoToEstimator() {
        this.showEstimator = true;
        return;
    // const summary = this.args.loanSummary;
    // const loanErrors = summary.errors;
    // if (!summary.summaryByLoanType.length) {
    //   this.showEstimatorBlockDialog = true;
    // } else {
    //   if (loanErrors.length === 0) {
    //     this.showMissingInfoDialog = false;
    //     this.showEstimator = true;
    //   } else {
    //     this.showMissingInfoDialog = true;
    //   }
    // }
    }
    @action
    didSaveEstimator(recommendation: RecommendationModel) {
        if (this.args.programType === 'idr') {
            this.router.transitionTo('authenticated.idr.dashboard.forgiveness-estimator.results', recommendation);
        } else if (this.args.programType === 'sf') {
            this.router.transitionTo('authenticated.repaying-student-debt.repayment-strategy-finder.dashboard.questionnaire.results', recommendation);
        }
    }
    static{
        template_44d667399ada469f9e1b88751a7764b6(`
    <Section>
      <:body>
        {{#if this.showEstimator}}
          {{#if (eq @programType "idr")}}
            <h2 class="text-midnight font-semibold mb-2">
              {{t "idr.estimator.default"}}
            </h2>
            <p class="font-semibold md:w-7/12">
              {{t "idr.estimator.complete"}}
            </p>
            <ForgivenessEstimatorForgivenessEstimatorForm
              @application="IDR"
              @loanSummary={{@loanSummary}}
              @didSave={{this.didSaveEstimator}}
              class="md:max-w-3xl"
            />
          {{else}}
            <h2 class="text-midnight font-semibold mb-2">
              {{t "common.strategy_finder.default"}}
            </h2>
            <p class="font-semibold">
              {{t "strategy_finder.complete"}}
            </p>
            <StrategyFinderStrategyFinderForm
              @user={{@user}}
              @application="STRATEGY_FINDER"
              @loanSummary={{@loanSummary}}
              @didSave={{this.didSaveEstimator}}
              class="md:max-w-3xl ml-4"
            />
          {{/if}}
        {{else}}
          <VStack class="text-center">
            <p class="text-left">
              {{#if (eq @programType "idr")}}
                {{t
                  "idr.estimator.idr_explanations"
                  htmlSafe=true
                  color="text-violet-800 font-semibold"
                }}
              {{else}}
                {{t
                  "strategy_finder.intro_explanation"
                  htmlSafe=true
                  color="text-violet-800 font-semibold"
                }}
              {{/if}}
            </p>
            <div>
              <Button @intent="primary" {{on "click" this.checkLoansAndGoToEstimator}}>
                {{t "idr.estimator.start"}}
              </Button>
            </div>
            {{svgJar
              "calculator-illustration"
              width="70%"
              height="70%"
              role="img"
              title=(t "svg.calculator_illustration")
            }}
          </VStack>
        {{/if}}
      </:body>
    </Section>

    <Modal
      @isOpen={{this.showMissingInfoDialog}}
      @onClose={{fn (mut this.showMissingInfoDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="full"
      as |m|
    >
      <m.Header>
        {{@person.firstName}},
        {{t "pslf_dashboard.actions.dialog.we_need_your_help"}}
      </m.Header>
      <m.Body>
        <p class="text-tio-gray-400 mb-5">
          {{t "pslf_dashboard.actions.dialog.please_provide_info"}}
        </p>
        <AccountsLinkedLoansSummary
          @accounts={{@accounts}}
          @allowOverrideEdits={{true}}
          @person={{@person}}
          @loanSummary={{@loanSummary}}
        />
      </m.Body>
      <m.Footer>
        <Footer
          @isRunning={{false}}
          @onSubmit={{this.checkLoansAndGoToEstimator}}
          @onCancel={{fn (mut this.showMissingInfoDialog) false}}
        />
      </m.Footer>
    </Modal>

    <Modal
      @isOpen={{this.showEstimatorBlockDialog}}
      @onClose={{fn (mut this.showEstimatorBlockDialog) false}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Body>
        <p class="text-xl text-center text-gray-600 font-semibold mb-4">
          {{t "pslf_dashboard.actions.dialog.before_proceeding"}}
        </p>

        <p>{{t "pslf_dashboard.actions.dialog.we_will_not_be_able"}}
        </p>
      </m.Body>
      <m.Footer>
        <Footer @isRunning={{false}} @onSubmit={{fn (mut this.showEstimatorBlockDialog) false}} />
      </m.Footer>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
