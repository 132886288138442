import { template as template_6a7b18d6ffa34086a1ba9a13d3225be9 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import { Divider } from 'tio-ui/components/utilities';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
const QuestionsLoanForgivenessTeacherComponent: TOC = template_6a7b18d6ffa34086a1ba9a13d3225be9(`
  {{! Teacher Loan Forgiveness }}
  <div class="mt-6">
    <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.teacher.tlf.title"}}</h1>
    <p class="pt-4">{{t "pslf.questions.teacher.tlf.description"}}</p>
    <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
      <div class="pr-3">
        <ul class="p-4 list-disc">
          <li>{{t "pslf.questions.teacher.tlf.item_1"}}</li>
          <li>{{t "pslf.questions.teacher.tlf.item_2"}}</li>
        </ul>
      </div>
      <div class="pl-3">
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.teacher.tlf.how_to_choose.title"}}
        </h1>
        <p class="pt-4">{{t "pslf.questions.teacher.tlf.how_to_choose.description"}}</p>
      </div>
    </div>
  </div>

  {{! Am I Eligible? }}
  <div class="mt-6">
    <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.am_i_eligible"}}</h1>
    <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
      <div class="pr-3">
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.teacher.borrower_eligibility.title"}}
        </h1>
        <p class="pt-4">{{t "pslf.questions.teacher.borrower_eligibility.description"}}</p>
      </div>
      <div class="pt-4 sm:pl-3 sm:pt-0">
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.teacher.loan_types.title"}}
        </h1>
        <p class="pt-4">{{t "pslf.questions.teacher.loan_types.description"}}</p>
      </div>
    </div>
  </div>

  {{! Plan Details }}
  <div class="mt-6">
    <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.plan_details"}}</h1>
    <div class="pt-6 pb-2 flex flex-col sm:grid sm:grid-cols-3">
      <div>
        <p class="pt-4 font-semibold text-gray-600">
          {{t "pslf.questions.teacher.plan.forgiven_after.title"}}
        </p>
        <p>{{t "pslf.questions.teacher.plan.forgiven_after.description"}}</p>
      </div>
      <div class="sm:ml-5">
        <p class="pt-4 font-semibold text-gray-600">
          {{t "pslf.questions.teacher.plan.max_forgiveness.title"}}
        </p>
        <p>{{t "pslf.questions.teacher.plan.max_forgiveness.description"}}</p>
      </div>
      <div>
        <p class="pt-4 font-semibold text-gray-600">{{t
            "pslf.questions.teacher.plan.tax_info.title"
          }}</p>
        <p>{{t "pslf.questions.teacher.plan.tax_info.description"}}</p>
      </div>
    </div>
    <Divider class="my-2" />
    <div class="flex flex-col sm:grid sm:grid-cols-2">
      <div class="p-3">
        <h1 class="font-semibold text-lg text-gray-600">
          {{t "pslf.questions.teacher.plan.pros.title"}}
        </h1>
        <ul class="p-4 pt-0 list-disc">
          <li>{{t "pslf.questions.teacher.plan.pros.item_1"}}</li>
          <li>{{t "pslf.questions.teacher.plan.pros.item_2"}}</li>
        </ul>
      </div>
      <div class="p-3">
        <h1 class="font-semibold text-lg text-gray-600">
          {{t "pslf.questions.income_driven.plan.cons.title"}}
        </h1>
        <ul class="p-4 pt-0 list-disc">
          <li>{{t "pslf.questions.teacher.plan.cons.item_1"}}</li>
          <li>{{t "pslf.questions.teacher.plan.cons.item_2"}}</li>
          <li>{{t "pslf.questions.teacher.plan.cons.item_3"}}</li>
        </ul>
      </div>
    </div>
  </div>

  {{! Next Steps }}
  <div class="mt-6">
    <h1 class="text-midnight text-xl font-semibold">{{t "pslf.questions.next_steps"}}</h1>
    <div class="pt-6 flex flex-col sm:grid sm:grid-cols-2">
      <div class="pr-3">
        <h1 class="font-semibold text-gray-600">
          {{t "pslf.questions.teacher.make_the_switch.title"}}
        </h1>
        <ul class="p-4 list-disc">
          <li>{{t "pslf.questions.teacher.make_the_switch.item_1" htmlSafe=true}}</li>
          <li>{{t "pslf.questions.teacher.make_the_switch.item_2"}}</li>
          <li>{{t "pslf.questions.teacher.make_the_switch.item_3" htmlSafe=true}}</li>
        </ul>
      </div>
      <div class="pl-3">
        <h1 class="font-semibold text-gray-600">{{t "pslf.questions.need_more_help.title"}}</h1>
        <p class="pt-4">{{t "pslf.questions.need_more_help.description" htmlSafe=true}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default QuestionsLoanForgivenessTeacherComponent;
