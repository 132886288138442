import { template as template_e049faab9558488babcc3e4d1656db78 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
export default RouteTemplate(template_e049faab9558488babcc3e4d1656db78(`
    {{pageTitle (t "tuition_assistance.default")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
