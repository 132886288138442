import { template as template_2e82b12dda0f45a5ba4ab7147f9fb73a } from "@ember/template-compiler";
import QuestionsLoanForgiveness from 'tio-employee/components/questions/loan-forgiveness';
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_2e82b12dda0f45a5ba4ab7147f9fb73a(`
    <div>
      <QuestionsLoanForgiveness
        @indexRoute="authenticated.questions-you-have.loan-forgiveness.index"
        @incomeRoute="authenticated.questions-you-have.loan-forgiveness.income-driven"
        @teacherRoute="authenticated.questions-you-have.loan-forgiveness.teacher"
        @stateRoute="authenticated.questions-you-have.loan-forgiveness.state-and-local"
      >
        {{outlet}}
      </QuestionsLoanForgiveness>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
