import { template as template_b5ad4e12a59a4163af63e13e855780ee } from "@ember/template-compiler";
import { Header, HStack, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { t } from 'ember-intl';
import ApplicationHistory from 'tio-common/components/tas/application-history';
import ChevronLeft from 'ember-static-heroicons/components/outline-24/chevron-left';
import NavTabs from 'tio-ui/components/nav-tabs';
import PaymentDetailsCard from 'tio-common/components/tas/payment-details-card';
import RouteTemplate from 'ember-route-template';
import type TasApplicationModel from 'tio-common/models/tas-application';
interface TasApplicationHistorySignature {
    Args: {
        model: TasApplicationModel;
    };
}
export default RouteTemplate<TasApplicationHistorySignature>(template_b5ad4e12a59a4163af63e13e855780ee(`
    <VStack>
      <Header>
        <div>
          {{! TAS.TODO: temporary routing until tas dashboard is complete }}
          <LinkTo
            class="flex justify-left items-center gap-4"
            @route="authenticated.tuition-assistance.dashboard"
          >
            <ChevronLeft class="w-5 inline" />
            {{t "tas.default"}}
          </LinkTo>
        </div>
      </Header>
      <NavTabs @model={{@model}} class="mb-4" as |navtabs|>
        <navtabs.item @route="authenticated.tas.applications.show" @model={{@model.id}}>
          {{t "tas.applications.default"}}
        </navtabs.item>
        <navtabs.item @route="authenticated.tas.applications.history" @model={{@model}}>
          {{t "tas.history.default"}}
        </navtabs.item>
      </NavTabs>
      <HStack>
        <VStack>
          <Section>
            <:header>{{@model.applicationName}}</:header>
            <:body>
              <ApplicationHistory @history={{@model.history}} />
            </:body>
          </Section>
        </VStack>
        <VStack class="md:w-1/3">
          <PaymentDetailsCard
            @isPaid={{@model.isPaid}}
            @requestedTotal={{@model.requestedTotal}}
            @payments={{@model.paymentsHistory}}
          />
        </VStack>
      </HStack>
    </VStack>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
