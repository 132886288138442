import { template as template_1d6e8d662a5d4b0696f9c1d422e4ad63 } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import MaterialIcon from 'tio-common/components/material-icon';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface PslfDashboardTodoCheckboxSignature {
    Args: {
        checked: boolean;
        onClick: () => void;
    };
    Element: HTMLButtonElement;
}
const PslfDashboardTodoCheckboxComponent: TOC<PslfDashboardTodoCheckboxSignature> = template_1d6e8d662a5d4b0696f9c1d422e4ad63(`
  <button class="mr-2" ...attributes type="button" {{on "click" @onClick}}>
    <MaterialIcon
      @icon={{if @checked "check_box" "check_box_outline_blank"}}
      class="text-ocean-600"
    />
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardTodoCheckboxComponent;
