import { template as template_ce98638816e24834b4fcb70a3b0f483b } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import pageTitle from 'ember-page-title/helpers/page-title';
export default RouteTemplate(template_ce98638816e24834b4fcb70a3b0f483b(`
    {{pageTitle (t "common.strategy_finder.default")}}
    {{outlet}}
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
