import { template as template_90120bd63ed349e88743dfbd4018574c } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import AdminNav from './admin-nav';
interface AppSidebarOwnerNavSignature {
    Args: {
        nav: unknown;
    };
}
const AppSidebarOwnerNavComponent: TOC<AppSidebarOwnerNavSignature> = template_90120bd63ed349e88743dfbd4018574c(`
  {{! Currently the account owner nav is the same as the admin nav.
  This might need to change in the future. - Julia 10.19.2022 }}
  {{! @glint-expect-error: no idea}}
  <AdminNav @nav={{@nav}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppSidebarOwnerNavComponent;
