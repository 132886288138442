import { template as template_93f3984a3658461b9e8532a48dc8e8e0 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import embedAwsQuicksight from 'tio-common/modifiers/embed-aws-quicksight';
interface S {
    Args: {
        model: {
            url: string;
        };
    };
}
export default RouteTemplate<S>(template_93f3984a3658461b9e8532a48dc8e8e0(`
    <div class="w-full">
      <div {{embedAwsQuicksight @model.url}}></div>
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
