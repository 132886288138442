import { template as template_e27f5547c7d443c38d86aba9786905f0 } from "@ember/template-compiler";
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import MaterialIcon from 'tio-common/components/material-icon';
interface AppHeaderInitialsIconSig {
    Args: {
        initials?: string;
    };
    Element: HTMLDivElement;
}
const AppHeaderInitialsIconComponent: TOC<AppHeaderInitialsIconSig> = template_e27f5547c7d443c38d86aba9786905f0(`
  <div
    class="flex items-center justify-center text-sm text-black font-bold uppercase rounded-full h-10 w-10 bg-mint-300 p-1"
    ...attributes
  >
    {{#if @initials}}
      {{@initials}}
    {{else}}
      <MaterialIcon @icon="person" />
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AppHeaderInitialsIconComponent;
