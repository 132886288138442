import { template as template_0d76ccf38caa4d9bae2017999b145ece } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { UploadFile } from 'ember-file-upload';
import { htmlSafe } from '@ember/template';
import type ObservableDocumentModel from 'tio-common/models/observable-document';
import { EXTRACTION_STATES } from 'tio-common/models/observable-document';
const progressBarInnerBg = (document?: ObservableDocumentModel)=>{
    const state = document?.extractionState || '';
    if (state === 'ExtractionState.PROCESSED') {
        return 'bg-green-600';
    } else if ([
        'ExtractionState.FAILED',
        'ExtractionState.INVALID'
    ].includes(state)) {
        return 'bg-red-600';
    }
    return 'bg-indigo-600';
};
const TOTAL_STEPS = 4;
interface Signature {
    Args: {
        upload: UploadFile;
        observableDocument?: ObservableDocumentModel;
        uploadError?: Error;
    };
    Element: HTMLDivElement;
}
export default class DocumentUploadProgressBar extends Component<Signature> {
    get progressBarInnerWidth() {
        const { upload, observableDocument } = this.args;
        const state = observableDocument?.extractionState;
        if (state) {
            return (EXTRACTION_STATES[state] / TOTAL_STEPS) * 100;
        } else if (upload) {
            return ((upload.progress || 0) / 100 / TOTAL_STEPS) * 100;
        } else {
            return 0;
        }
    }
    get progressBarInnerClasses() {
        const baseClasses = [
            'h-2',
            'rounded-full',
            'transition-[width,background-color]'
        ];
        if (this.args.uploadError) {
            return [
                ...baseClasses,
                'bg-red-700'
            ].join(' ');
        }
        const innerBg = progressBarInnerBg(this.args.observableDocument);
        return [
            ...baseClasses,
            `${innerBg}`
        ].join(' ');
    }
    get progressBarInnerStyles() {
        return htmlSafe(`width: ${this.progressBarInnerWidth}%;`);
    }
    static{
        template_0d76ccf38caa4d9bae2017999b145ece(`
    <div class="overflow-hidden rounded-full bg-gray-200">
      <div class={{this.progressBarInnerClasses}} style={{this.progressBarInnerStyles}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
