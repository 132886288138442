import { template as template_aad76994cce74fc4a69747ef76afb282 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import AppReadSecurityButton from 'tio-employee/components/app/read-security-button';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
import { Section, VStack } from 'tio-ui/components/layout';
interface PslfDashboardStartNewFormFaqsSig {
    Element: HTMLElement;
}
const PslfDashboardStartNewFormFaqsComponent: TOC<PslfDashboardStartNewFormFaqsSig> = template_aad76994cce74fc4a69747ef76afb282(`
  <Section>
    <:header>{{t "pslf.start_new.questions_about_form"}}</:header>
    <:body>
      <VStack @collapsed={{true}}>
        <Section>
          <:header>{{t "pslf.start_new.is_form_new"}}</:header>
          <:body>
            <div class="pl-8">
              {{t "pslf.start_new.is_form_new_answer"}}
            </div>
          </:body>
        </Section>
        <Section>
          <:header>{{t "pslf.start_new.if_already_submitted"}}</:header>
          <:body>
            <div class="pl-8">
              {{t "pslf.start_new.if_already_submitted_answer"}}
            </div>
          </:body>
        </Section>
        <Section>
          <:header>{{t "pslf.start_new.why_submit_form"}}</:header>
          <:body>
            <div class="pl-8">
              {{t "pslf.start_new.why_submit_form_answer"}}
            </div>
          </:body>
        </Section>
        <Section>
          <:header>{{t "pslf.start_new.what_happens_on_submit"}}</:header>
          <:body>
            <div class="pl-8">
              {{t "pslf.start_new.what_happens_on_submit_answer"}}
            </div>
          </:body>
        </Section>
        <Section>
          <:header>{{t "pslf.start_new.what_happens_to_loans"}}</:header>
          <:body>
            <div class="pl-8">
              {{t "pslf.start_new.what_happens_to_loans_answer"}}
            </div>
          </:body>
        </Section>
        <Section>
          <AppReadSecurityButton />
        </Section>
      </VStack>
    </:body>
  </Section>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PslfDashboardStartNewFormFaqsComponent;
