import { template as template_53f2e562c0384358baa24ba88d5fefb1 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import LoginHere from '../login-here';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import type { TemplateOnlyComponent as TOC } from '@ember/component/template-only';
interface AuthRegisterNotLaunchedComponentSignature {
    Args: {
        companyName: string;
        date: string;
        days: number;
    };
}
const AuthRegisterNotLaunchedComponent: TOC<AuthRegisterNotLaunchedComponentSignature> = template_53f2e562c0384358baa24ba88d5fefb1(`
  <div class="p-5">
    <p class="text-xl mb-6">
      {{t
        "login.not_launched"
        companyName=@companyName
        date=(safeFormatDate @date month="short" day="2-digit" year="numeric")
        days=@days
      }}
    </p>
    <LoginHere />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AuthRegisterNotLaunchedComponent;
