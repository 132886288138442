import { template as template_97d59870f6144401ad9a30f5fd6e7fb2 } from "@ember/template-compiler";
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import TuitionAssistanceFormsApprovalCoursesCompletionReview from 'tio-employee/components/tuition-assistance/forms/approval/courses-completion-review';
import type TasApplicationModel from 'tio-common/models/tas-application';
interface S {
    Args: {
        model: TasApplicationModel;
    };
}
export default RouteTemplate<S>(template_97d59870f6144401ad9a30f5fd6e7fb2(`
    <h3 class="font-semibold my-2 text-midnight">
      {{t "tuition_assistance.preapproval_app.application_review"}}
    </h3>

    <TuitionAssistanceFormsApprovalCoursesCompletionReview @model={{@model}} />
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
