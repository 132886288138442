import { template as template_e2d3b6bc9a804ef0968b254620a06030 } from "@ember/template-compiler";
import { Button } from 'tio-ui/components/buttons';
import { fn } from '@ember/helper';
import { not } from 'tio-ui/utilities';
import { on } from '@ember/modifier';
import { t } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import CardsGeneric from 'tio-common/components/tuition-assistance/cards/generic';
import Component from '@glimmer/component';
import CourseCardOverviewTable from 'tio-common/components/tuition-assistance/program-details/app-details/course-card/overview-table';
import MaterialIcon from 'tio-common/components/material-icon';
import Pencil from 'ember-static-heroicons/components/outline-24/pencil';
import TioErrorMessages from 'tio-common/components/tio/error-messages';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type TASCourseModel from 'tio-common/models/tas-course';
import Trash from 'ember-static-heroicons/components/outline-24/trash';
export interface TuitionAssistanceFormsCoursesCourseReviewCourseCardSignature {
    Args: {
        index: number;
        course: TASCourseModel;
        errors: unknown[];
        onRemove: () => void;
    };
}
export default class TuitionAssistanceFormsCoursesCourseReviewCourseCard extends Component<TuitionAssistanceFormsCoursesCourseReviewCourseCardSignature> {
    @tracked
    isExpanded = false;
    get courseHasMissingFields() {
        const filteredErrors = this.args.errors.filter(// @ts-expect-error: what is this type?
        (error)=>error.courseIndex === this.args.index);
        return !!filteredErrors.length;
    }
    static{
        template_e2d3b6bc9a804ef0968b254620a06030(`
    <CardsGeneric>
      <:headerTitle>
        {{@course.displayName}}
      </:headerTitle>
      <:headerRight>
        <Button @appearance="outlined" @intent="danger" {{on "click" @onRemove}}>
          <Trash class="w-5" />
        </Button>
      </:headerRight>
      <:body>
        <CourseCardOverviewTable @course={{@course}} />
        {{#if this.isExpanded}}
          <div class="my-4">
            <p class="text-base font-semibold mb-1">
              {{t "attachments"}}
            </p>
            <ul>
              {{#each @course.tasAssets as |asset|}}
                <li class="font-semibold text-ocean-600 mb-2 underline text-sm">
                  <a
                    href={{asset.signedUrl}}
                    target="_blank"
                    rel="noopener noreferrer"
                  >{{asset.filename}}</a>
                </li>
              {{else}}
                <li class="text-sm">{{t "common.none"}}</li>
              {{/each}}
            </ul>
          </div>
        {{/if}}
      </:body>
      <:footer>
        {{#if @errors}}
          <TioErrorMessages
            @showErrors={{this.courseHasMissingFields}}
            @error={{t "tuition_assistance.program_details.courses.fill_in_required_fields"}}
            class="text-sm flex gap-2"
            @icon="warning"
          />
        {{/if}}
        <div class="flex justify-between">
          <button
            type="button"
            class="font-semibold text-sm text-tio-gray-600"
            {{on "click" (fn (mut this.isExpanded) (not this.isExpanded))}}
          >
            {{#if this.isExpanded}}
              <MaterialIcon @icon="expand_less" class="align-middle" />
            {{else}}
              <MaterialIcon @icon="expand_more" class="align-middle" />
            {{/if}}
            {{t "tuition_assistance.program_details.courses.view_attachments"}}
          </button>
          <Button
            {{on
              "click"
              (transitionTo
                "authenticated.tuition-assistance.programs.instance.course-applications.show.pre-approval.edit-course"
                @course.id
              )
            }}
            title={{t "common.edit"}}
            @appearance="outlined"
            class="mx-1"
          >
            <Pencil class="w-5" />
          </Button>
        </div>
      </:footer>
    </CardsGeneric>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
