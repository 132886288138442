import { template as template_125ff2943f304c15baf3e2c4f4ba0a20 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import AuthLoginRoleSelect from 'tio-employee/components/auth/login/role-select';
import { service } from '@ember/service';
import type SessionContextService from 'tio-employee/services/session-context';
class RoleSelectRoute extends Component {
    @service
    sessionContext: SessionContextService;
    static{
        template_125ff2943f304c15baf3e2c4f4ba0a20(`
    <main class="h-screen mx-auto pb-20 sm:pb-0 overflow-y-auto">
      <AuthLoginRoleSelect @onSelect={{this.sessionContext.setCurrentRole}} />
    </main>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(RoleSelectRoute);
