import { template as template_9ddfb5dae9af48e886ac4243b7cdd986 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import LoadingSpinner from 'tio-employee/components/tas/loading-spinner';
export default RouteTemplate(template_9ddfb5dae9af48e886ac4243b7cdd986(`
    <div class="flex h-screen w-screen items-center justify-center">
      <LoadingSpinner />
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
