import { template as template_2a53578162fa446997b4109f37dd9511 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import PslfDashboardCardInfo from 'tio-employee/components/pslf/dashboard/card-info';
import PslfDashboardTodo from 'tio-employee/components/pslf/dashboard/todo';
import PslfDashboardTodoCheckbox from 'tio-employee/components/pslf/dashboard/todo/checkbox';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import urlFor from 'ember-router-helpers/helpers/url-for';
import type AnswerProfileModel from 'tio-common/models/answer-profile';
import type IntlService from 'ember-intl/services/intl';
import type RouterService from '@ember/routing/router';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { Button } from 'tio-ui/components/buttons';
export interface RecommendationsRepaymentPlanTodoSignature {
    Args: {
        answerProfile: AnswerProfileModel;
    };
}
export default class RecommendationsRepaymentPlanTodoComponent extends Component<RecommendationsRepaymentPlanTodoSignature> {
    @service
    router: RouterService;
    @service
    intl: IntlService;
    get answerProfile() {
        return this.args.answerProfile;
    }
    get isCompleted() {
        return !!this.answerProfile?.changedRepaymentPlanAt;
    }
    get linkToRec() {
        return this.idrOrPslfRoute('link');
    }
    get textForRecCard() {
        return this.idrOrPslfRoute('text');
    }
    @action
    idrOrPslfRoute(option: 'link' | 'text') {
        if (option === 'link') {
            return this.router.currentRouteName === 'authenticated.idr.dashboard.index' ? 'authenticated.idr.dashboard.recommendations' : 'authenticated.pslf.dashboard.recommendations';
        }
        if (option === 'text') {
            return this.router.currentRouteName === 'authenticated.pslf.dashboard.index' ? this.intl.t('recommendations.change_repayment_plan_pslf') : this.intl.t('recommendations.change_repayment_plan_idr');
        }
    }
    @action
    async toggleCompleted() {
        const profile = this.answerProfile;
        try {
            profile.changedRepaymentPlanAt = this.isCompleted ? '' : new Date().toISOString();
            await profile.save();
        } catch (e) {
            console.error(e);
            profile?.rollbackAttributes();
        }
    }
    static{
        template_2a53578162fa446997b4109f37dd9511(`
    <PslfDashboardTodo
      @title={{t "recommendations.update_repayment_plan"}}
      @isComplete={{this.isCompleted}}
    >
      <:leftHeader>
        <PslfDashboardTodoCheckbox
          @checked={{this.isCompleted}}
          @onClick={{this.toggleCompleted}}
        />
      </:leftHeader>
      <:body>
        <PslfDashboardCardInfo @icon="change_circle" @text={{this.textForRecCard}} class="w-fit" />
      </:body>
      <:actions>
        <Button
          {{on
            "click"
            (transitionTo (concat (urlFor this.linkToRec) "?recommendation=changeRepaymentPlan"))
          }}
          @appearance="outlined"
        >
          {{t "recommendations.update_repayment_plan"}}
        </Button>
      </:actions>
    </PslfDashboardTodo>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
