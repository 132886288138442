import { template as template_2a9d98605fb64fb494ee44ce179cd45f } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Modal } from 'tio-ui/components/modal';
import { notEq, eq } from 'tio-ui/utilities';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import type { IntlService } from 'ember-intl';
import type RecommendationModel from 'tio-common/models/recommendation';
import type StrategyFinderService from 'tio-common/services/strategy-finder';
export interface ForgivenessEstimatorEstimatorSummaryDialogSignature {
    Args: {
        programType: string;
        recommendation: RecommendationModel;
        didCancel?: () => void;
    };
}
export default class ForgivenessEstimatorEstimatorSummaryDialog extends Component<ForgivenessEstimatorEstimatorSummaryDialogSignature> {
    @service
    strategyFinder: StrategyFinderService;
    @service
    intl: IntlService;
    get programType() {
        return this.args.programType;
    }
    get inputSummary() {
        const inputSummary = this.args.recommendation?.formattedInputSummary;
        if (this.programType != 'sf') {
            inputSummary.monthsOfPayments = inputSummary.monthsOfPayments / 12;
        }
        inputSummary.income = this.strategyFinder.formatCentsRange([
            inputSummary.income
        ]);
        inputSummary.before2007 === 'before-october-2007' ? (inputSummary.before2007 = this.intl.t('yes')) : (inputSummary.before2007 = this.intl.t('no'));
        if (inputSummary.degree) {
            inputSummary.degree = this.intl.t(`forgiveness_estimator.${inputSummary.degree}`);
        }
        if (inputSummary.affordPayment) {
            inputSummary.affordPayment = this.intl.t(`${inputSummary.affordPayment}`);
        }
        return inputSummary;
    }
    @action
    cancel() {
        this.args.didCancel?.();
    }
    @action
    formatMoney(centsRange = []) {
        return this.strategyFinder.formatCentsRange(centsRange);
    }
    static{
        template_2a9d98605fb64fb494ee44ce179cd45f(`
    <Modal
      @isOpen={{true}}
      @onClose={{this.cancel}}
      class="min-h-64"
      @backdrop="faded"
      @size="xl"
      as |m|
    >
      <m.Header>
        {{#if (notEq this.programType "sf")}}
          {{t "forgiveness_estimator.results.your_monthly_payment"}}
        {{else}}
          {{t "strategy_finder.your_payment_and_sf_input"}}
        {{/if}}
      </m.Header>
      <m.Body>
        <div class="text-sm text-tio-gray-700 flex flex-col">
          <div class="flex my-2">
            <p class="font-semibold">
              {{t "forgiveness_estimator.results.gross_income"}}:
            </p>
            <span class="ml-1">{{this.inputSummary.income}}</span>
          </div>
          <div class="flex my-2">
            <p class="font-semibold">{{t "forgiveness_estimator.family_size"}}:</p>
            <span class="ml-1">{{this.inputSummary.familySize}}</span>
          </div>
          <div class="flex my-2">
            {{#if (eq this.programType "sf")}}
              <p class="font-semibold">
                {{t "strategy_finder.months_of_qualifying_payments"}}:
              </p>
            {{else}}
              <p class="font-semibold">
                {{t "forgiveness_estimator.results.number_of_years"}}:
              </p>
            {{/if}}
            <span class="ml-1">{{this.inputSummary.monthsOfPayments}}</span>
          </div>
          <div class="flex my-2">
            <p class="font-semibold">
              {{t "forgiveness_estimator.results.loans_before_2007"}}:
            </p>
            <span class="ml-1">{{this.inputSummary.before2007}}</span>
          </div>
          {{#if this.inputSummary.degree}}
            <div class="flex my-2">
              <p class="font-semibold">
                {{t "forgiveness_estimator.grad_or_undergrad"}}:
              </p>
              <span class="ml-1">{{this.inputSummary.degree}}</span>
            </div>
          {{/if}}
          {{#if this.inputSummary.affordPayment}}
            <div class="flex my-2">
              <p class="font-semibold">
                {{t "forgiveness_estimator.afford_payments"}}:
              </p>
              <span class="ml-1">{{this.inputSummary.affordPayment}}</span>
            </div>
          {{/if}}
        </div>
      </m.Body>
    </Modal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
