import { template as template_98b587e1e5a044babe5f8170de5d664e } from "@ember/template-compiler";
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ChartPie from 'ember-apache-echarts/components/chart/pie';
import Component from '@glimmer/component';
// eslint-disable-next-line ember/no-at-ember-render-modifiers -- should move this to addon
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didResize from 'tio-common/modifiers/did-resize';
import safeFormatNumber from 'tio-common/helpers/safe-format-number';
import type { ChartBounds } from './chart-types';
import type LoanModel from 'tio-common/models/loan';
export interface LoanSummaryChartSignature {
    Args: {
        loans: LoanModel[];
    };
}
export default class LoanSummaryChart extends Component<LoanSummaryChartSignature> {
    @tracked
    chartBounds?: ChartBounds;
    get loans() {
        return this.args.loans;
    }
    get loanData() {
        const summaryChartData = this.loans.map((loan)=>{
            return {
                name: loan.name,
                value: loan.currentBalance
            };
        });
        return summaryChartData;
    }
    @action
    didResizeContainer(element: HTMLElement) {
        const bounds = element.getBoundingClientRect();
        this.chartBounds = {
            height: bounds.height - 40,
            width: bounds.width - 40
        };
    }
    static{
        template_98b587e1e5a044babe5f8170de5d664e(`
    <div
      class="flex flex-col justify-center items-center w-full h-full"
      {{didInsert this.didResizeContainer}}
      {{didResize this.didResizeContainer debounce=50}}
    >
      {{#if this.chartBounds}}
        {{! @glint-expect-error: ts work on charts is happening }}
        <ChartPie
          @width={{this.chartBounds.width}}
          @height={{this.chartBounds.height}}
          @variant="donut"
          @data={{this.loanData}}
        >
          {{! prettier-ignore }}
          {{!-- template-lint-disable no-triple-curlies --}}
          {{! @glint-expect-error }}
          <:itemTooltip as |item|>
            {{{item.marker}}}
            <b>{{item.data.name}}:</b>
            {{safeFormatNumber item.value style="currency" currency="USD"}}
          </:itemTooltip>
        </ChartPie>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
