import { template as template_962449ba5db241ebb3a927e7f4acb326 } from "@ember/template-compiler";
import { action } from '@ember/object';
import { Button } from 'tio-ui/components/buttons';
import { Collapsible } from 'tio-ui/components/utilities';
import { getSingleValueForTasField } from 'tio-common/utils/tuition-assistance/fields';
import { Header, Section, VStack } from 'tio-ui/components/layout';
import { LinkTo } from '@ember/routing';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t, type IntlService } from 'ember-intl';
import { tracked } from '@glimmer/tracking';
import Badge from 'tio-ui/components/badge';
import ChevronRight from 'ember-static-heroicons/components/outline-24/chevron-right';
import Component from '@glimmer/component';
import MyPaymentActivity from 'tio-employee/components/tuition-assistance/dashboard/my-payment-activity';
import pageTitle from 'ember-page-title/helpers/page-title';
import RouteTemplate from 'ember-route-template';
import safeFormatDate from 'tio-common/helpers/safe-format-date';
import TioAlert from 'tio-common/components/tio/alert';
import transitionTo from 'ember-router-helpers/helpers/transition-to';
import type { LimitsStatusDataSignature } from 'tio-common/services/tuition-assistance';
import type SessionContextService from 'tio-employee/services/session-context';
import type TasProgramInstanceModel from 'tio-common/models/tas-program-instance';
import type TasProgramTemplate from 'tio-common/models/tas-program-template';
import User from 'ember-static-heroicons/components/outline-24/user';
const string = String;
export interface S {
    Args: {
        model: {
            isHeldParticipant: boolean;
            activeInstances: TasProgramInstanceModel[];
            templates: TasProgramTemplate[];
            pastInstances: TasProgramInstanceModel[];
            limitsStatus: LimitsStatusDataSignature;
        };
    };
}
class TasDashboard extends Component<S> {
    @service
    intl: IntlService;
    @service
    sessionContext: SessionContextService;
    @tracked
    isOpen = false;
    get isDependentProgramsEnabled() {
        return (this.sessionContext.currentEmployee?.company.companySetting.tuitionAssistance?.isDependentProgramsEnabled || false);
    }
    getExpenseType(template: TasProgramTemplate) {
        const expenseType = getSingleValueForTasField('EXPENSE_TYPE', template.fields);
        if (expenseType) {
            return `${expenseType}s`;
        } else {
            return 'COURSES';
        }
    }
    @action
    toggle() {
        this.isOpen = !this.isOpen;
    }
    @action
    statusText(state: string): string {
        if (this.intl.exists(`common.status_message.to.${state}`)) {
            return this.intl.t(`common.status_message.to.${state}`);
        }
        return this.intl.t('common.status_message.to.TAS.ProgramInstanceState.UPDATED_ON');
    }
    static{
        template_962449ba5db241ebb3a927e7f4acb326(`
    {{pageTitle (t "dashboard")}}
    <VStack>
      <Header>{{t "tuition_assistance.default"}}</Header>
      {{#if @model.isHeldParticipant}}
        <TioAlert @type="warning" class="my-4" @allowDismiss={{true}}>
          <:header>
            <p class="font-bold">
              {{t "tuition_assistance.held_participant_warnings.dashboard_title"}}
            </p>
          </:header>
          <:body>
            <p class="text-sm">
              {{t "tuition_assistance.held_participant_warnings.dashboard_description"}}
            </p>
          </:body>
        </TioAlert>
      {{/if}}
      <Section>
        <:header>
          <div class="flex justify-between items-center">
            <p>{{t "tuition_assistance.dashboard.payment_activity.payments"}}</p>
            <LinkTo
              @route="authenticated.tuition-assistance.my-payment-details"
              class="flex items-center tio-copy gap-x-2"
            >
              {{t "tuition_assistance.dashboard.payment_activity.payment_details"}}
              <span class="no-underline">
                <ChevronRight />
              </span>
            </LinkTo>
          </div>
        </:header>
        <:body>
          <h3 class="font-semibold mt-4">
            {{t "tuition_assistance.dashboard.payment_activity.total_payments"}}
          </h3>
          <p>
            {{t "tuition_assistance.dashboard.payment_activity.note"}}
          </p>
          <MyPaymentActivity class="m-2 mb-16" @limitsStatus={{@model.limitsStatus}} />
        </:body>
      </Section>
      <Section>
        <:header>
          <div class="flex justify-between items-center">
            <p>{{t "common.active"}}</p>
            {{#if this.isDependentProgramsEnabled}}
              <Button
                @intent="primary"
                @appearance="outlined"
                {{on "click" (transitionTo "authenticated.tuition-assistance.dependent-programs")}}
                data-test-dependent-programs-button
                class="flex items-end"
              >
                <User class="w-4 mr-2" />
                {{t "common.tuition_assistance.dependent_programs_button"}}
              </Button>
            {{/if}}
          </div>
        </:header>
        <:body>
          <VStack @collapsed={{true}}>
            {{#each @model.activeInstances as |instance|}}
              <Section>
                <ProgramDescription @instance={{instance}} />

                {{#if instance.tasApplications.length}}
                  <p class="uppercase mt-4 text-sm">
                    {{this.getExpenseType instance.tasProgramTemplate}}
                  </p>
                  <ul role="list" class="space-y-6 py-4">
                    {{#each instance.tasApplications as |application|}}
                      <li class="group relative flex gap-x-4">
                        <div
                          class="absolute -bottom-6 left-0 top-0 flex w-6 justify-center last:bottom-0"
                        >
                          <div class="w-px bg-gray-200 group-last:hidden"></div>
                        </div>
                        <div
                          class="relative flex size-6 flex-none items-center justify-center bg-white"
                        >
                          <div class="size-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300"></div>
                        </div>
                        <div>
                          <div class="flex items-center gap-x-2">
                            <LinkTo
                              @route="authenticated.tas.applications.show"
                              @model={{application.id}}
                              class="font-semibold text-lg text-ocean-600 hover:underline"
                            >
                              {{string application.displayNameOrSemesterCode}}
                            </LinkTo>
                            <Badge @intent={{application.badgeIntent}}>
                              {{application.badgeLabel}}
                            </Badge>
                          </div>
                          <p class="text-sm">
                            <span>
                              {{! TODO: this is clunky. Need to review labels}}
                              {{this.statusText application.latestStateChangeLabel}}
                              {{t "common.on"}}
                            </span>
                            {{safeFormatDate
                              application.coursesBeginDate
                              month="long"
                              day="2-digit"
                              year="numeric"
                            }}
                          </p>
                        </div>
                      </li>
                    {{/each}}
                  </ul>
                {{/if}}
              </Section>
            {{/each}}
          </VStack>
        </:body>
      </Section>
      <Section>
        <:header>{{t "tas.dashboard.available_benefits"}}</:header>
        <:body>
          <VStack @collapsed={{true}}>
            {{#each @model.templates as |template|}}
              <Section>
                <LinkTo
                  @route="authenticated.tuition-assistance.program-overview"
                  @model={{template.id}}
                  class="font-semibold text-lg text-ocean-600 hover:underline"
                >
                  {{template.programDescription}}
                </LinkTo>
                <p class="flex justify-between">
                  {{template.programName}}
                </p>
                <p class="text-sm">
                </p>
              </Section>
            {{/each}}
          </VStack>
        </:body>
      </Section>
      {{! TODO: figure out the logic on when we show this. We want to show things that only closed out recently
      in the main list. Thinking like 30 days. Also figma shows this as a history page on it's own, but that placement is
      currently occupied by the dependent button so we need a home for that first? }}
      {{#if @model.pastInstances.length}}
        <VStack class="!mb-8">
          <Section>
            <:body>
              <button
                type="button"
                class="font-semibold text-lg flex justify-between w-full"
                {{on "click" this.toggle}}
              >
                {{t "tas.history.past_programs"}}
                <ChevronRight class="size-6 text-purple-700 {{if this.isOpen 'rotate-90'}}" />
              </button>
              <Collapsible @isOpen={{this.isOpen}}>
                <VStack @collapsed={{true}}>
                  {{#each @model.pastInstances as |instance|}}
                    <Section>
                      <ProgramDescription @instance={{instance}} />
                    </Section>
                  {{/each}}
                </VStack>
              </Collapsible>
            </:body>
          </Section>
        </VStack>
      {{/if}}
    </VStack>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default RouteTemplate(TasDashboard);
interface ProgramDescriptionSignature {
    Args: {
        instance: TasProgramInstanceModel;
    };
}
class ProgramDescription extends Component<ProgramDescriptionSignature> {
    @service
    intl: IntlService;
    @action
    estimatedStartDate(instance: TasProgramInstanceModel) {
        const startDate = getSingleValueForTasField('ESTIMATED_PROGRAM_BEGIN', instance.fields) as string;
        const formattedDate = this.intl.formatDate(startDate, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        if (startDate) {
            return formattedDate;
        } else {
            return '';
        }
    }
    static{
        template_962449ba5db241ebb3a927e7f4acb326(`
    <div class="flex justify-between items-start">
      <div>
        <p class="font-medium flex items-center gap-x-2">
          <LinkTo
            @route="authenticated.tas.programs.show"
            @model={{@instance.id}}
            class="font-semibold text-lg text-ocean-600 hover:underline"
          >
            {{@instance.programName}}
          </LinkTo>
          <Badge @intent={{@instance.badgeIntent}}>
            {{@instance.badgeLabel}}
          </Badge>
        </p>
        <p class="font-medium">{{@instance.institutionName}}</p>
        {{#if (this.estimatedStartDate @instance)}}
          <p class="text-sm">
            <span class="font-semibold">{{t "start_date"}}</span>
            {{this.estimatedStartDate @instance}}
          </p>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
