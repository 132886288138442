import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type StoreService from 'tio-common/services/store';
import type ObservableDocumentModel from 'tio-common/models/observable-document';

type RouteParams = {
  observable_document_id: string;
};

export default class AuthenticatedObservabilityConfirmRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  async model(params: RouteParams) {
    const id = params['observable_document_id'];

    // fetch loan here for account information section
    const observableDocument = await this.store.findRecord('observable-document', id, {
      include: 'observable-asset,financial-institution,account-transactions',
    });

    return observableDocument;
  }

  async afterModel(model: { observableDocument: ObservableDocumentModel }) {
    if (model.observableDocument.subsistenceState !== 'SubsistenceState.IMPLICIT') {
      this.router.transitionTo(
        'authenticated.observability.documents.show',
        model.observableDocument
      );
    }
  }
}
